var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tooltip"},[_c('VTooltip',{staticClass:"ml-2",attrs:{"color":"rgba(255, 255, 255, 0)","max-width":"500","dark":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('GlobalIcon',{attrs:{"icon":"question-circle","color":"secondary","size":_vm.iconSize}})],1)]}}]),model:{value:(_vm.onHover),callback:function ($$v) {_vm.onHover=$$v},expression:"onHover"}},[_c('div',{staticClass:"tooltip-container"},[_c('span',{staticClass:"tooltip-title"},[_vm._l((_vm.values),function(value,index){return [_vm._v(" "+_vm._s(("" + (_vm.$t(("commons.standards." + (value.type))))))+" "),(_vm.haveMultipleValues && index < _vm.values.length - 1)?[_vm._v(" /")]:_vm._e()]})],2),_vm._l((_vm.values),function(value,index){return [_c('div',{key:("tooltip-" + (value.type))},[(value.normsType && value.unit)?_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.breakLine(
                _vm.$t(("commons.sentences.tooltips." + (value.subType ? value.subType + '.' : '') + (value.type)), {
                  firstValue: value.normsType.base[0],
                  secondValue: value.normsType.base[1],
                  unit: value.unit
                })
              )
            )}}):(value.normsType)?_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.breakLine(
                _vm.$t(("commons.sentences.tooltips." + (value.subType ? value.subType + '.' : '') + (value.type)), {
                  firstValue: value.normsType.base[0],
                  secondValue: value.normsType.base[1]
                })
              )
            )}}):(value.unit)?_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.breakLine(
                _vm.$t(("commons.sentences.tooltips." + (value.subType ? value.subType + '.' : '') + (value.type)), {
                  unit: value.unit
                })
              )
            )}}):_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.breakLine(
                _vm.$t(
                  ("commons.sentences.tooltips." + (value.subType ? value.subType + '.' : '') + (value.type)),
                  value.data
                )
              )
            )}}),(_vm.haveMultipleValues && index < _vm.values.length - 1)?_c('VDivider',{staticClass:"my-3"}):_vm._e()],1)]})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }