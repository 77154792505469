<template>
  <div class="document">
    <!--region Header-->
    <ExportationViewerPage :total="pageTotal" page="1">
      <ExportationFrameHeader v-bind="{ headerInformations }" />

      <ExportationFrameWalkingChartBanner
        v-bind="{ aggregates, norms }"
        :display-metrics="analysis.display_metrics"
        :degraded="isDegradedActivity"
      />

      <ExportationFrameWalkingChartWalkProfileDegraded
        v-if="isDegradedActivity"
        v-bind="{ aggregates, displayMetrics }"
        class="top-margin"
      />
      <ExportationFrameWalkingChartWalkProfile
        v-else
        v-bind="{ aggregates, displayMetrics }"
        class="top-margin"
      />
    </ExportationViewerPage>
    <!--endregion-->

    <!--region Content-->

    <!--Degraded-->
    <ExportationViewerPage v-if="isDegradedActivity" :total="pageTotal" page="2">
      <ExportationFrameWalkingChartFootProgressionAngle v-bind="{ aggregates, displayMetrics }" />
      <ExportationFrameComments class="top-margin-lg" v-bind="{ analysisComments: comments }" :height="300" />
    </ExportationViewerPage>
    <!--Standard-->
    <template v-else>
      <ExportationViewerPage :total="pageTotal" page="2">
        <div class="align-row">
          <ExportationFrameWalkingChartGaitLine v-bind="{ aggregates, displayMetrics }" />
          <ExportationFrameWalkingChartPropulsionRatio v-bind="{ aggregates, displayMetrics }" />
        </div>
        <ExportationFrameWalkingChartPronationAngles
          v-if="!advancedMode"
          class="top-margin-lg"
          v-bind="{ aggregates, norms, displayMetrics }"
          :height="advancedMode ? 450 : 230"
        />
        <ExportationFrameWalkingChartPronationAngles
          v-else
          v-bind="{ aggregates, norms, displayMetrics, advancedMode }"
          class="top-margin-lg"
          :height="advancedMode ? 450 : 330"
        />
        <!--Comments-->
        <ExportationFrameComments
          v-if="!advancedMode"
          class="top-margin-lg"
          v-bind="{ analysisComments: comments }"
          :height="300"
        />
      </ExportationViewerPage>
      <!--region PAGE 3-->
      <ExportationViewerPage v-if="advancedMode" :total="pageTotal" page="3">
        <ExportationFrameWalkingChartPronationAngles
          v-if="advancedMode"
          v-bind="{ aggregates, norms, displayMetrics }"
          :height="200"
        />
        <div>
          <ExportationFrameWalkingChartFootProgressionAngle v-bind="{ aggregates, displayMetrics }" />
          <ExportationFrameWalkingChartClearanceSteppage v-bind="{ aggregates, displayMetrics }" />
        </div>
      </ExportationViewerPage>
      <ExportationViewerPage v-if="advancedMode" :total="pageTotal" page="4">
        <ExportationFrameComments v-bind="{ analysisComments: comments }" :height="270" vertical />
      </ExportationViewerPage>
      <!--endregion-->
    </template>

    <!--endregion-->
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ExportationViewerPage from '@/components/exportation/ExportationViewerPage.vue';
import ExportationFrameHeader from '@/components/exportation/frames/ExportationFrameHeader.vue';
import ExportationFrameComments from '@/components/exportation/frames/ExportationFrameComments.vue';
// Charts
import ExportationFrameWalkingChartBanner from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartBanner.vue';
import ExportationFrameWalkingChartWalkProfile from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartWalkProfile.vue';
import ExportationFrameWalkingChartWalkProfileDegraded from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartWalkProfileDegraded.vue';
import ExportationFrameWalkingChartGaitLine from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartGaitLine.vue';
import ExportationFrameWalkingChartPropulsionRatio from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartPropulsionRatio.vue';
import ExportationFrameWalkingChartPronationAngles from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartPronationAngles.vue';
import ExportationFrameWalkingChartFootProgressionAngle from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartFootProgressionAngle.vue';
import ExportationFrameWalkingChartClearanceSteppage from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartClearanceSteppage.vue';

export default defineComponent({
  name: 'ExportationFrameWalking',
  components: {
    ExportationViewerPage,
    ExportationFrameHeader,
    ExportationFrameComments,
    // Charts
    ExportationFrameWalkingChartBanner,
    ExportationFrameWalkingChartWalkProfile,
    ExportationFrameWalkingChartWalkProfileDegraded,
    ExportationFrameWalkingChartGaitLine,
    ExportationFrameWalkingChartPropulsionRatio,
    ExportationFrameWalkingChartPronationAngles,
    ExportationFrameWalkingChartFootProgressionAngle,
    ExportationFrameWalkingChartClearanceSteppage
  },
  props: {
    analysis: {
      type: Object,
      required: true
    },
    advancedMode: {
      type: Boolean,
      required: true
    },
    pageTotal: {
      type: Number,
      required: true,
      default: 0
    },
    headerInformations: {
      type: Object,
      required: true
    },
    comments: {
      type: Array,
      required: false
    }
  },
  setup(properties) {
    const aggregates = computed(() => properties.analysis.metrics.aggregates);
    const displayMetrics = computed(() => properties.analysis.display_metrics.aggregates);
    const norms = computed(() => properties.analysis.metrics.norms);
    const isDegradedActivity = computed(() => properties.analysis.analysis_validity === 1);

    return {
      aggregates,
      displayMetrics,
      norms,
      // Flags
      isDegradedActivity
    };
  }
});
</script>

<style lang="scss" scoped>
.top-margin {
  margin-top: 30px;
  &-sm {
    margin-top: 20px;
  }
  &-lg {
    margin-top: 50px;
  }
}
.align-row {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 50px;
}
</style>
