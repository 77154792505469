







// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'ElementChipNormValue',
  props: {
    value: {
      type: Number,
      required: true
    },
    indicator: {
      type: String || null
    },
    unit: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(properties) {
    const myIndicator = computed(() => {
      if (properties.indicator === null || properties.indicator === undefined) {
        return 'default';
      }
      return properties.indicator;
    });
    return { myIndicator };
  }
});
