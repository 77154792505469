<template>
  <div class="chart-card__results">
    <img :src="cdn('image', 'side-hop-jump-profile-graph.svg')" width="164" class="mb-5" />
    <div class="jump-leg-wrapper">
      <div class="jump-leg-side">
        <span class="jump-leg-side-char jump-leg-side-char-left" :class="{ 'top-2px': isMdAndLarger }">
          {{ $t('commons.standards.left').charAt(0) }}
        </span>
        <img
          :src="cdn('image', 'footprint-left.svg')"
          :width="isMdAndLarger ? '42' : '35'"
          :class="{ 'mb-1': isMdAndLarger }"
        />
        <span v-if="isMdAndLarger" class="leg-subtitle">{{ $t('commons.standards.jump-left-leg') }}</span>
      </div>
      <template v-if="isMdAndLarger && !isExportMode">
        <div class="jump-leg-bg jump-leg-bg-8"></div>
        <div class="jump-leg-bg jump-leg-bg-16"></div>
      </template>
      <div class="jump-leg-bg">
        <span v-if="isLgAndLarger" class="mr-2 font-weight-600">
          {{ $tc('commons.standards.contact') }} :
        </span>
        <VChip small color="white" class="mr-2 font-weight-bold">{{ data.leftContactTime1 }}</VChip>
      </div>
      <div class="jump-leg-bg jump-leg-bg--left">
        <span v-if="isMdAndLarger" class="mr-2 white--text font-weight-600">
          {{ $t('commons.standards.flight') }} :
        </span>
        <VChip small color="white" class="font-weight-bold">{{ data.leftFlight }}</VChip>
      </div>
      <div class="jump-leg-bg">
        <span v-if="isLgAndLarger" class="mr-2 font-weight-600">
          {{ $tc('commons.standards.contact') }} :
        </span>
        <VChip small color="white" class="mr-2 font-weight-bold">{{ data.leftContactTime2 }}</VChip>
      </div>
      <template v-if="isMdAndLarger && !isExportMode">
        <div class="jump-leg-bg jump-leg-bg-16"></div>
        <div class="jump-leg-bg jump-leg-bg-8"></div>
      </template>
    </div>
    <div class="jump-between-leg-wrapper" :style="isMdAndLarger ? 'gap: 10rem' : 'gap: 6rem'">
      <div class="d-flex flex-column align-center">
        <div
          class="rounded-chip rounded-chip--left font-weight-bold"
          :class="{ 'opacity-0': data.contact1DiffSide !== 'left' }"
        >
          {{ $t('commons.standards.left').charAt(0) }}
        </div>
        <VChip x-small color="black" class="white--text my-1 font-weight-bold px-2">
          {{ data.contact1Diff }}
        </VChip>
        <div
          class="rounded-chip rounded-chip--right font-weight-bold"
          :class="{ 'opacity-0': data.contact1DiffSide !== 'right' }"
        >
          {{ $t('commons.standards.right').charAt(0) }}
        </div>
      </div>
      <div class="d-flex flex-column align-center">
        <div
          class="rounded-chip rounded-chip--left font-weight-bold"
          :class="{ 'opacity-0': data.flightDiffSide !== 'left' }"
        >
          {{ $t('commons.standards.left').charAt(0) }}
        </div>
        <VChip x-small color="black" class="white--text my-1 font-weight-bold px-2">
          {{ data.flightDiff }}
        </VChip>
        <div
          class="rounded-chip rounded-chip--right font-weight-bold"
          :class="{ 'opacity-0': data.flightDiffSide !== 'right' }"
        >
          {{ $t('commons.standards.right').charAt(0) }}
        </div>
      </div>
      <div class="d-flex flex-column align-center">
        <div
          class="rounded-chip rounded-chip--left font-weight-bold"
          :class="{ 'opacity-0': data.contact2DiffSide !== 'left' }"
        >
          {{ $t('commons.standards.left').charAt(0) }}
        </div>
        <VChip x-small color="black" class="white--text my-1 font-weight-bold px-2">
          {{ data.contact2Diff }}
        </VChip>
        <div
          class="rounded-chip rounded-chip--right font-weight-bold"
          :class="{ 'opacity-0': data.contact2DiffSide !== 'right' }"
        >
          {{ $t('commons.standards.right').charAt(0) }}
        </div>
      </div>
    </div>
    <div class="jump-leg-wrapper">
      <div class="jump-leg-side">
        <span class="jump-leg-side-char jump-leg-side-char-right" :class="{ 'top-2px': isMdAndLarger }">
          {{ $t('commons.standards.right').charAt(0) }}
        </span>
        <img
          :src="cdn('image', 'footprint-right.svg')"
          :width="isMdAndLarger ? '42' : '35'"
          :class="{ 'mb-1': isMdAndLarger }"
        />
        <span v-if="isMdAndLarger" class="leg-subtitle">{{ $t('commons.standards.jump-right-leg') }}</span>
      </div>
      <template v-if="isMdAndLarger && !isExportMode">
        <div class="jump-leg-bg jump-leg-bg-8"></div>
        <div class="jump-leg-bg jump-leg-bg-16"></div>
      </template>
      <div class="jump-leg-bg">
        <span v-if="isLgAndLarger" class="mr-2 font-weight-600">
          {{ $tc('commons.standards.contact') }} :
        </span>
        <VChip small color="white" class="mr-2 font-weight-bold">{{ data.rightContactTime1 }}</VChip>
      </div>
      <div class="jump-leg-bg jump-leg-bg--right">
        <span v-if="isMdAndLarger" class="mr-2 white--text font-weight-600">
          {{ $t('commons.standards.flight') }} :
        </span>
        <VChip small color="white" class="font-weight-bold">{{ data.rightFlight }}</VChip>
      </div>
      <div class="jump-leg-bg">
        <span v-if="isLgAndLarger" class="mr-2 font-weight-600">
          {{ $tc('commons.standards.contact') }} :
        </span>
        <VChip small color="white" class="mr-2 font-weight-bold">{{ data.rightContactTime2 }}</VChip>
      </div>
      <template v-if="isMdAndLarger && !isExportMode">
        <div class="jump-leg-bg jump-leg-bg-16"></div>
        <div class="jump-leg-bg jump-leg-bg-8"></div>
      </template>
    </div>
  </div>
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useVuetifyBreakpoints } from '@/utils/vuetify-breakpoints.utils';
import { useCDN } from '@/utils/cdn.utils';
import { useRehab } from '@/utils/rehab.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabSideHopJumpProfile',
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    isExportMode: {
      type: Boolean,
      default: false
    }
  },
  setup(properties) {
    const { isMdAndLarger, isLgAndLarger } = useVuetifyBreakpoints();
    const { cdn } = useCDN();
    const { getFormattedData, getSimplifiedUnit } = useRehab();

    const data = {
      leftFlight: `${getFormattedData(
        properties.aggregates.jumping_flight_time.left_foot.avg
      )}${getSimplifiedUnit(properties.aggregates.jumping_flight_time.unit)}`,
      rightFlight: `${getFormattedData(
        properties.aggregates.jumping_flight_time.right_foot.avg
      )}${getSimplifiedUnit(properties.aggregates.jumping_flight_time.unit)}`,
      leftContactTime1: `${getFormattedData(
        properties.aggregates.jumping_stance_time_left_stance.left_foot.avg
      )}${getSimplifiedUnit(properties.aggregates.jumping_stance_time_left_stance.unit)}`,
      leftContactTime2: `${getFormattedData(
        properties.aggregates.jumping_stance_time_left_stance.right_foot.avg
      )}${getSimplifiedUnit(properties.aggregates.jumping_stance_time_left_stance.unit)}`,
      rightContactTime1: `${getFormattedData(
        properties.aggregates.jumping_stance_time_right_stance.left_foot.avg
      )}${getSimplifiedUnit(properties.aggregates.jumping_stance_time_right_stance.unit)}`,
      rightContactTime2: `${getFormattedData(
        properties.aggregates.jumping_stance_time_right_stance.right_foot.avg
      )}${getSimplifiedUnit(properties.aggregates.jumping_stance_time_right_stance.unit)}`,
      contact1Diff: `${getFormattedData(
        properties.aggregates.jumping_stance_time_left_stance.global.avg_diff_R_L
      )}${getSimplifiedUnit(properties.aggregates.jumping_stance_time_left_stance.unit)}`,
      contact1DiffSide: properties.aggregates.jumping_stance_time_left_stance.global.side,
      flightDiff: `${getFormattedData(
        properties.aggregates.jumping_flight_time.global.avg_diff_R_L
      )}${getSimplifiedUnit(properties.aggregates.jumping_flight_time.unit)}`,
      flightDiffSide: properties.aggregates.jumping_flight_time.global.side,
      contact2Diff: `${getFormattedData(
        properties.aggregates.jumping_stance_time_right_stance.global.avg_diff_R_L
      )}${getSimplifiedUnit(properties.aggregates.jumping_stance_time_right_stance.unit)}`,
      contact2DiffSide: properties.aggregates.jumping_stance_time_right_stance.global.side
    };

    return {
      // Values
      isMdAndLarger,
      isLgAndLarger,
      data,
      // Methods
      cdn
    };
  }
});
</script>

<style lang="scss" scoped>
.leg-subtitle {
  font-weight: 600;
  font-size: 14px;
  color: #020c27;
  opacity: 0.5;
}

.font-weight-600 {
  font-weight: 600;
}

.jump-leg-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  gap: 4px;
}

.jump-leg-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;

  &-char {
    font-weight: bold;
    position: absolute;
    color: white;
    font-size: 0.8rem;

    &-left {
      left: 55%;
    }

    &-right {
      left: 53%;
    }
  }
}

.jump-leg-bg {
  background-color: #fafafa;
  border-radius: 1000px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.5rem;
  &-8 {
    padding: 0;
    width: 8px;
  }
  &-16 {
    padding: 0;
    width: 16px;
  }

  &--left {
    background-color: #b298dc;
  }

  &--right {
    background-color: #00a0d1;
  }
}

.jump-between-leg-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.25rem 0;
  font-weight: 600;
}

.top-2px {
  top: 2px;
}

.rounded-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  height: 1rem;
  width: 1rem;
  font-size: 0.6rem;
  line-height: 1rem;

  &--left {
    background-color: #b298dc;
  }

  &--right {
    background-color: #00a0d1;
  }
}
</style>
