














// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartWalkingClearanceSteppage from '@/components/charts/walking/ChartWalkingClearanceSteppage.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingChartClearanceSteppage',
  components: {
    ChartWalkingClearanceSteppage
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    }
  },
  setup(properties) {
    const valid = computed(() => properties.displayMetrics.walking_plantar_flexion_angle_foot_in);
    const simplified = computed(
      () =>
        properties.displayMetrics.walking_plantar_flexion_angle_foot_in &&
        !properties.displayMetrics.walking_plantar_flexion_angle_foot_out
    );

    return {
      // Valid
      valid,
      simplified
    };
  }
});
