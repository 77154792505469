


















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRehabGeneralParameters from '@/components/charts/rehab/ChartRehabGeneralParameters.vue';
// Import utils ------------------------------------------------------------------------------------
import { useRehab } from '@/utils/rehab.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameJumpingChartSideHopGeneralParameters',
  components: {
    ChartRehabGeneralParameters
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    page: {
      type: String,
      required: true
    },
    activityDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    }
  },
  setup(properties) {
    const { getSideHopGeneralParametersSimplifiedData, getSideHopGeneralParametersAdvancedData } = useRehab();

    const data = computed(() =>
      properties.page === '2'
        ? getSideHopGeneralParametersSimplifiedData(properties.aggregates)
        : getSideHopGeneralParametersAdvancedData(properties.aggregates)
    );

    return {
      data
    };
  }
});
