












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRunningSpeedCadence from '@/components/charts/running/ChartRunningSpeedCadence.vue';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameRunningChartSpeedCadenceDetailled',
  components: {
    ChartRunningSpeedCadence
  },
  props: {
    segments: {
      type: Object,
      required: true
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    }
  },
  setup() {
    const { isImperial } = useUnit().currentUnit();

    return {
      isImperial
    };
  }
});
