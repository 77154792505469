<template>
  <div class="chart">
    <div v-if="!hideTitle" class="chart__title">
      {{ $t('commons.standards.jump-profile') }}
    </div>
    <div v-if="activityDate" class="chart__date">{{ $moment(activityDate).format('LLL') }}</div>
    <div class="chart__content">
      <div class="chart-card">
        <VRow class="chart-card__subtitle">
          <VCol cols="6"
            ><span class="chart-card__subtitle--left">{{ $t('commons.standards.left') }}</span></VCol
          >
          <VCol cols="6"
            ><span class="chart-card__subtitle--right">{{ $t('commons.standards.right') }}</span></VCol
          >
        </VRow>
        <VRow>
          <VCol v-for="(result, index) in data" :key="index" cols="6">
            <div class="card-title">{{ $t(result.title) }}</div>
            <div class="card-subtitle">
              <span>{{ $t(result.subtitle) }}</span>
              <span>{{ result.data }}</span>
            </div>
          </VCol>
        </VRow>
      </div>
    </div>
  </div>
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useVuetifyBreakpoints } from '@/utils/vuetify-breakpoints.utils';
import { useRehab } from '@/utils/rehab.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameTripleHopJumpProfileSummary',
  components: {},
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    activityDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    }
  },
  setup(properties) {
    const { isMdAndLarger, isLgAndLarger } = useVuetifyBreakpoints();
    const { getFormattedData, getSimplifiedUnit } = useRehab();

    const data = [
      {
        title: 'commons.standards.average',
        subtitle: 'commons.standards.stance-time',
        data: `${getFormattedData(
          properties.aggregates.jumping_stance_time.left_foot.avg
        )} ${getSimplifiedUnit(properties.aggregates.jumping_stance_time.unit)}`
      },
      {
        title: 'commons.standards.average',
        subtitle: 'commons.standards.stance-time',
        data: `${getFormattedData(
          properties.aggregates.jumping_stance_time.right_foot.avg
        )} ${getSimplifiedUnit(properties.aggregates.jumping_stance_time.unit)}`
      },
      {
        title: 'commons.standards.average',
        subtitle: 'commons.standards.swing-time',
        data: `${getFormattedData(
          properties.aggregates.jumping_flight_time.left_foot.avg
        )} ${getSimplifiedUnit(properties.aggregates.jumping_flight_time.unit)}`
      },
      {
        title: 'commons.standards.average',
        subtitle: 'commons.standards.swing-time',
        data: `${getFormattedData(
          properties.aggregates.jumping_flight_time.left_foot.avg
        )} ${getSimplifiedUnit(properties.aggregates.jumping_flight_time.unit)}`
      }
    ];

    return {
      // Values
      data,
      isMdAndLarger,
      isLgAndLarger
    };
  }
});
</script>

<style lang="scss" scoped>
.chart {
  &__title {
    font-size: 1rem;
  }

  &__content {
    margin-top: 20px;
  }
}

.chart-card {
  height: auto !important;
}

.chart-card__subtitle {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  &--left {
    color: #b298dc;
  }
  &--right {
    color: #00a0d1;
  }
}

.card-title {
  font-size: 1rem;
}

.card-subtitle {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: black;
}
</style>
