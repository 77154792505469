
















































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingWalkProfile',
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    }
  },
  setup() {
    const { isImperial } = useUnit().currentUnit();

    const valueType = computed(() => (isImperial.value ? 'imp_avg' : 'avg'));

    const strideLengthUnit = computed(() => getUnit(EValueTypeUnit.StrideLength));
    return { valueType, strideLengthUnit };
  }
});
