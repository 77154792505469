


















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRehabGeneralParametersAdvanced from '@/components/charts/rehab/ChartRehabGeneralParametersAdvanced.vue';
// Import utils ------------------------------------------------------------------------------------
import { useRehab } from '@/utils/rehab.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameJumpingChartTripleHopGeneralParametersAdvanced',
  components: {
    ChartRehabGeneralParametersAdvanced
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    segments: {
      type: Object,
      required: true
    },
    page: {
      type: String,
      required: true
    },
    activityDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    }
  },
  setup(properties) {
    const { getTripleHopGeneralParametersAdvancedData } = useRehab();
    const tripleHopGeneralParametersAdvancedData = getTripleHopGeneralParametersAdvancedData(
      properties.aggregates,
      properties.segments
    );

    const data = computed(() =>
      properties.page === '3'
        ? tripleHopGeneralParametersAdvancedData.slice(0, 4)
        : tripleHopGeneralParametersAdvancedData.slice(5)
    );

    return {
      data
    };
  }
});
