








































































































































































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, toRefs } from '@vue/composition-api';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export enum ERange {
  VeryLow = 'very_low',
  Low = 'low',
  Neutral = 'neutral',
  High = 'high',
  VeryHigh = 'very_high'
}

export default defineComponent({
  name: 'ChartWalkingClearanceSteppage',
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    simplified: {
      type: Boolean,
      required: true
    }
  },
  setup(properties) {
    const { isImperial } = useUnit().currentUnit();
    const { aggregates } = toRefs(properties);

    function normalizeAngle(angle: number) {
      return angle * -1;
    }

    function getIndicatorColor(indicator: string) {
      switch (indicator) {
        case ERange.VeryLow:
        case ERange.VeryHigh:
          return '#FF4242';
        case ERange.High:
        case ERange.Low:
          return '#FFD632';
        case ERange.Neutral:
          return '#71CC26';
        default:
          return '#e0e0e0';
      }
    }

    const data: any = computed(() => {
      return {
        left: {
          color: '#FFBE13',
          textColor: '#B36200',
          height: aggregates.value.walking_minimum_toe_clearance
            ? aggregates.value.walking_minimum_toe_clearance.left_foot[isImperial.value ? 'imp_avg' : 'avg']
            : null,
          toesOff: properties.simplified
            ? null
            : {
                value: aggregates.value.walking_plantar_flexion_angle_foot_out.left_foot.avg,
                type: aggregates.value.walking_plantar_flexion_angle_foot_out.left_foot.range,
                indicator: getIndicatorColor(
                  aggregates.value.walking_plantar_flexion_angle_foot_out.left_foot.range
                )
              },
          heelStrike: {
            value: normalizeAngle(aggregates.value.walking_plantar_flexion_angle_foot_in.left_foot.avg),
            type: aggregates.value.walking_plantar_flexion_angle_foot_in.left_foot.range,
            indicator: getIndicatorColor(
              aggregates.value.walking_plantar_flexion_angle_foot_in.left_foot.range
            )
          }
        },
        right: {
          color: '#2462FD',
          textColor: '#0E2D86',
          height: aggregates.value.walking_minimum_toe_clearance
            ? aggregates.value.walking_minimum_toe_clearance.right_foot[isImperial.value ? 'imp_avg' : 'avg']
            : null,
          toesOff: properties.simplified
            ? null
            : {
                value: aggregates.value.walking_plantar_flexion_angle_foot_out.right_foot.avg,
                type: aggregates.value.walking_plantar_flexion_angle_foot_out.right_foot.range,
                indicator: getIndicatorColor(
                  aggregates.value.walking_plantar_flexion_angle_foot_out.right_foot.range
                )
              },
          heelStrike: {
            value: normalizeAngle(aggregates.value.walking_plantar_flexion_angle_foot_in.right_foot.avg),
            type: aggregates.value.walking_plantar_flexion_angle_foot_in.right_foot.range,
            indicator: getIndicatorColor(
              aggregates.value.walking_plantar_flexion_angle_foot_in.right_foot.range
            )
          }
        }
      };
    });

    const angles: any = computed(() => {
      return {
        neutral: {
          toesOff: {
            feet: 'M78.571 46.863c4.927-6.656 2.953-14.767-3.602-19.3-6.554-4.532-15.37-2.031-19.713 7.42-4.342 9.453-11.112 20.879-16.605 28.528a14.051 14.051 0 0 0-1.669 3.004c-2.168 3.037-4.209 5.13-5.72 6.68-.45.46-.853.874-1.199 1.25-1.867 2.03-2.73 6.916-1.365 9.544s5.746 5.682 9.068 7.09c3.321 1.408 10.09 5.839 12.915 10.843l.635 1.137c2.639 4.741 5.533 9.943 11.282 9.943h17.206c2.282 0 4.064-2.427 2.814-5.003-.724-1.495-2.282-1.648-3.919-1.809-1.185-.117-2.412-.237-3.395-.874-2.341-1.517-6.125-5.033-7.731-9.81-.396-1.179-1.104-2.943-1.945-5.041-2.174-5.424-5.242-13.076-6.134-18.588 2.882-4.329 8.09-10.941 12.595-16.66 2.583-3.278 4.934-6.263 6.482-8.354Z',
            mask_1:
              'M82.174 111.946c-4.818-.688-14.5-2.056-17.614-2.445-4-.5-10-4.5-11-9.5-.747-3.736-9.31-8.868-15.678-12.684-2.154-1.291-4.057-2.432-5.322-3.316-2.495-1.747-2.66-6.602-2.195-9.879-.105.11-.206.218-.302.323-1.867 2.03-2.73 6.916-1.365 9.544s5.746 5.682 9.068 7.09c3.321 1.408 10.09 5.838 12.915 10.843.21.373.422.753.635 1.136 2.639 4.742 5.533 9.943 11.282 9.943h17.206c.93 0 1.778-.403 2.37-1.055Z',
            mask_2:
              'M48.637 78.578c.92.92.922 2.47-.28 2.967a5.998 5.998 0 0 1-7.84-7.84c.498-1.202 2.047-1.2 2.967-.28l2.576 2.577 2.577 2.576Z',
            feetAngleShape: 'M25 114.001a40 40 0 0 1 10.646-27.172L65 114H25Z',
            feetAngleStroke: 'm16 74 42 38'
          },
          heelStrike: {
            feet: 'M228.91 53.5c-3.303-7.594-11.318-9.94-18.525-6.53-7.207 3.41-9.451 12.296-3.433 20.782 6.018 8.487 12.532 20.063 16.412 28.645a14.045 14.045 0 0 0 1.766 2.944c1.548 3.398 2.341 6.213 2.928 8.298.175.62.331 1.176.484 1.663.825 2.632 4.627 5.823 7.587 5.955 2.96.131 7.799-2.136 10.68-4.308 2.882-2.173 10.107-5.819 15.857-5.764.429.004.864.011 1.303.018 5.428.086 11.384.18 14.26-4.799l8.608-14.9c1.141-1.976-.069-4.733-2.927-4.939-1.658-.12-2.57 1.153-3.528 2.49-.694.968-1.413 1.97-2.457 2.503-2.485 1.27-7.425 2.788-12.368 1.79-1.219-.246-3.102-.516-5.341-.836-5.787-.829-13.952-1.998-19.174-3.98-2.31-4.66-5.434-12.48-8.136-19.24-1.549-3.877-2.958-7.405-3.996-9.791Z',
            mask_1:
              'M287.107 82.921c-3.006 3.83-9.035 11.53-10.93 14.032-2.435 3.214-8.902 6.41-13.735 4.776-3.611-1.221-12.342 3.63-18.835 7.236-2.196 1.22-4.137 2.298-5.536 2.951-2.761 1.287-7.051-.997-9.658-3.039.043.147.086.288.128.423.826 2.632 4.627 5.823 7.588 5.955 2.96.131 7.798-2.136 10.68-4.308 2.881-2.173 10.107-5.819 15.856-5.764.43.004.865.011 1.303.018 5.429.086 11.384.18 14.26-4.798l8.608-14.901c.465-.806.54-1.741.271-2.58ZM226.368 49.534c1.01 1.103 1.872 2.426 2.541 3.965 1.038 2.386 2.448 5.914 3.997 9.79 2.701 6.761 5.826 14.58 8.135 19.24 1.567.595 3.398 1.117 5.35 1.578-.568.373-1.126.768-1.67 1.153-2.052 1.452-3.886 2.75-5.175 2.104-5.355-2.684-13.181-15.931-17.85-28.261-1.708-4.51.789-8.42 4.672-9.57Z',
            mask_2:
              'M241.416 95.283c1.258-.337 2.601.436 2.431 1.726a6.003 6.003 0 0 1-10.715 2.87c-.793-1.032-.017-2.373 1.241-2.71l3.522-.943 3.521-.943Z',
            feetAngleShape: 'M273.186 105.916a39.97 39.97 0 0 1 .826 8.104l-40.023-.019 39.197-8.085Z',
            feetAngleStroke: 'm234.282 113.641 69.369-17.64'
          }
        },
        high: {
          toesOff: {
            feet: 'M90.858 51.026c6.306-5.368 6.22-13.715.862-19.614-5.358-5.9-14.512-5.46-20.882 2.763-6.37 8.223-15.55 17.82-22.634 24.026-.938.822-1.7 1.675-2.305 2.548-2.8 2.467-5.261 4.043-7.084 5.21-.543.348-1.03.66-1.451.947-2.278 1.555-4.226 6.118-3.491 8.987.735 2.87 4.31 6.836 7.227 8.96 2.916 2.123 8.505 7.971 10.124 13.485l.362 1.251c1.495 5.216 3.137 10.938 8.736 12.24h19.49s2.316-2.476 1.158-4.296c-.765-1.202-2.087-1.239-3.451-1.278-1.265-.036-2.566-.072-3.498-1.038l-.324-.334c-1.878-1.932-4.111-4.228-4.567-8.97-.12-1.237-.409-3.116-.753-5.35-.89-5.775-2.145-13.923-1.766-19.493 3.788-3.564 10.359-8.825 16.041-13.375 3.258-2.608 6.224-4.983 8.206-6.67Z',
            mask_1:
              'M70.82 111.829a746.358 746.358 0 0 0-7.793-2.966c-3.783-1.393-8.721-6.647-8.563-11.744.118-3.808-7.061-10.746-12.4-15.905-1.806-1.745-3.4-3.287-4.432-4.435-2.034-2.266-1.096-7.032.099-10.119-.127.084-.25.166-.367.246-2.278 1.555-4.226 6.118-3.491 8.987.735 2.87 4.31 6.836 7.227 8.96 2.916 2.123 8.505 7.971 10.124 13.485l.362 1.251c1.495 5.216 3.137 10.938 8.736 12.24H70.82ZM93.915 47.438c-.763 1.288-1.779 2.499-3.058 3.587-1.98 1.687-4.946 4.061-8.205 6.67-5.682 4.55-12.253 9.81-16.04 13.374-.114 1.671-.081 3.574.045 5.575a58.514 58.514 0 0 0-1.587-1.26c-1.986-1.54-3.761-2.917-3.518-4.337 1.011-5.903 11.412-17.24 21.852-25.292 3.817-2.944 8.282-1.695 10.51 1.683Z',
            mask_2:
              'M54.52 75.138c.687 1.105.338 2.614-.946 2.826a6.002 6.002 0 0 1-6.825-7.278 6 6 0 0 1 .965-2.133c.757-1.058 2.265-.706 2.953.399l1.926 3.093 1.926 3.093Z',
            feetAngleShape: 'M25.095 113.087c0-8.976 2.03-19.07 13.081-27.601l20.568 27.601h-33.65Z',
            feetAngleStroke: 'M27.442 70.289 58 112'
          },
          heelStrike: {
            feet: 'M213.914 53.676c-4.269-7.098-12.522-8.374-19.221-4.05-6.699 4.323-7.761 13.426-.684 21.052 7.077 7.626 15.049 18.25 20.018 26.251a14.053 14.053 0 0 0 2.136 2.688c1.98 3.167 3.134 5.854 3.989 7.844.254.592.482 1.122.697 1.585 1.163 2.502 5.349 5.167 8.301 4.911 2.952-.257 7.452-3.138 10.025-5.668 2.573-2.531 9.259-7.092 14.966-7.789.427-.052.859-.102 1.294-.152 5.393-.626 11.31-1.311 13.51-6.623l6.584-15.9c.874-2.108-.687-4.682-3.548-4.513-1.659.099-2.396 1.479-3.172 2.93-.561 1.05-1.142 2.138-2.107 2.803-2.298 1.584-6.997 3.736-12.028 3.393-1.241-.084-3.143-.105-5.405-.13-5.845-.064-14.093-.155-19.53-1.438-2.899-4.318-7.019-11.66-10.582-18.01-2.043-3.64-3.902-6.954-5.243-9.184Z',
            mask_1:
              'M275.46 75.23c-2.48 4.19-7.449 12.612-9.001 15.34-1.993 3.506-7.987 7.52-12.992 6.533-3.74-.738-11.761 5.213-17.726 9.638-2.018 1.497-3.801 2.82-5.102 3.649-2.57 1.638-7.122-.065-9.974-1.748l.183.402c1.163 2.502 5.349 5.167 8.301 4.911 2.953-.257 7.453-3.137 10.025-5.668 2.573-2.531 9.259-7.092 14.966-7.789.427-.052.859-.102 1.295-.152 5.393-.625 11.309-1.311 13.509-6.623l6.585-15.899a3.197 3.197 0 0 0-.069-2.594ZM210.874 50.076c1.146.962 2.174 2.16 3.039 3.599 1.341 2.23 3.2 5.543 5.243 9.183 3.563 6.35 7.683 13.692 10.582 18.01 1.631.385 3.515.663 5.511.864-.514.444-1.016.91-1.505 1.362-1.844 1.708-3.493 3.235-4.855 2.763-5.661-1.96-15.152-14.07-21.394-25.683-2.283-4.247-.32-8.45 3.379-10.098Z',
            mask_2:
              'M231.779 93.463c1.203-.498 2.635.093 2.635 1.394a6.01 6.01 0 0 1-1.757 4.243 6.01 6.01 0 0 1-4.245 1.759 5.995 5.995 0 0 1-4.245-1.756c-.921-.92-.327-2.35.876-2.848l3.368-1.396 3.368-1.396Z',
            feetAngleShape:
              'M268.328 97.093c4.904 4.471 5.685 14.204 5.684 16.927l-40.023-.019 34.339-16.909Z',
            feetAngleStroke: 'm234.245 113.391 61.231-29.888'
          }
        },
        very_high: {
          toesOff: {
            feet: 'M90.858 51.026c6.306-5.368 6.22-13.715.862-19.614-5.358-5.9-14.512-5.46-20.882 2.763-6.37 8.223-15.55 17.82-22.634 24.026-.938.822-1.7 1.675-2.305 2.548-2.8 2.467-5.261 4.043-7.084 5.21-.543.348-1.03.66-1.451.947-2.278 1.555-4.226 6.118-3.491 8.987.735 2.87 4.31 6.836 7.227 8.96 2.916 2.123 8.505 7.971 10.124 13.485l.362 1.251c1.495 5.216 3.137 10.938 8.736 12.24h19.49s2.316-2.476 1.158-4.296c-.765-1.202-2.087-1.239-3.451-1.278-1.265-.036-2.566-.072-3.498-1.038l-.324-.334c-1.878-1.932-4.111-4.228-4.567-8.97-.12-1.237-.409-3.116-.753-5.35-.89-5.775-2.145-13.923-1.766-19.493 3.788-3.564 10.359-8.825 16.041-13.375 3.258-2.608 6.224-4.983 8.206-6.67Z',
            mask_1:
              'M70.82 111.829a746.358 746.358 0 0 0-7.793-2.966c-3.783-1.393-8.721-6.647-8.563-11.744.118-3.808-7.061-10.746-12.4-15.905-1.806-1.745-3.4-3.287-4.432-4.435-2.034-2.266-1.096-7.032.099-10.119-.127.084-.25.166-.367.246-2.278 1.555-4.226 6.118-3.491 8.987.735 2.87 4.31 6.836 7.227 8.96 2.916 2.123 8.505 7.971 10.124 13.485l.362 1.251c1.495 5.216 3.137 10.938 8.736 12.24H70.82ZM93.915 47.438c-.763 1.288-1.779 2.499-3.058 3.587-1.98 1.687-4.946 4.061-8.205 6.67-5.682 4.55-12.253 9.81-16.04 13.374-.114 1.671-.081 3.574.045 5.575a58.514 58.514 0 0 0-1.587-1.26c-1.986-1.54-3.761-2.917-3.518-4.337 1.011-5.903 11.412-17.24 21.852-25.292 3.817-2.944 8.282-1.695 10.51 1.683Z',
            mask_2:
              'M54.52 75.138c.687 1.105.338 2.614-.946 2.826a6.002 6.002 0 0 1-6.825-7.278 6 6 0 0 1 .965-2.133c.757-1.058 2.265-.706 2.953.399l1.926 3.093 1.926 3.093Z',
            feetAngleShape: 'M25.095 113.087c0-8.976 2.03-19.07 13.081-27.601l20.568 27.601h-33.65Z',
            feetAngleStroke: 'M27.442 70.289 58 112'
          },
          heelStrike: {
            feet: 'M213.914 53.676c-4.269-7.098-12.522-8.374-19.221-4.05-6.699 4.323-7.761 13.426-.684 21.052 7.077 7.626 15.049 18.25 20.018 26.251a14.053 14.053 0 0 0 2.136 2.688c1.98 3.167 3.134 5.854 3.989 7.844.254.592.482 1.122.697 1.585 1.163 2.502 5.349 5.167 8.301 4.911 2.952-.257 7.452-3.138 10.025-5.668 2.573-2.531 9.259-7.092 14.966-7.789.427-.052.859-.102 1.294-.152 5.393-.626 11.31-1.311 13.51-6.623l6.584-15.9c.874-2.108-.687-4.682-3.548-4.513-1.659.099-2.396 1.479-3.172 2.93-.561 1.05-1.142 2.138-2.107 2.803-2.298 1.584-6.997 3.736-12.028 3.393-1.241-.084-3.143-.105-5.405-.13-5.845-.064-14.093-.155-19.53-1.438-2.899-4.318-7.019-11.66-10.582-18.01-2.043-3.64-3.902-6.954-5.243-9.184Z',
            mask_1:
              'M275.46 75.23c-2.48 4.19-7.449 12.612-9.001 15.34-1.993 3.506-7.987 7.52-12.992 6.533-3.74-.738-11.761 5.213-17.726 9.638-2.018 1.497-3.801 2.82-5.102 3.649-2.57 1.638-7.122-.065-9.974-1.748l.183.402c1.163 2.502 5.349 5.167 8.301 4.911 2.953-.257 7.453-3.137 10.025-5.668 2.573-2.531 9.259-7.092 14.966-7.789.427-.052.859-.102 1.295-.152 5.393-.625 11.309-1.311 13.509-6.623l6.585-15.899a3.197 3.197 0 0 0-.069-2.594ZM210.874 50.076c1.146.962 2.174 2.16 3.039 3.599 1.341 2.23 3.2 5.543 5.243 9.183 3.563 6.35 7.683 13.692 10.582 18.01 1.631.385 3.515.663 5.511.864-.514.444-1.016.91-1.505 1.362-1.844 1.708-3.493 3.235-4.855 2.763-5.661-1.96-15.152-14.07-21.394-25.683-2.283-4.247-.32-8.45 3.379-10.098Z',
            mask_2:
              'M231.779 93.463c1.203-.498 2.635.093 2.635 1.394a6.01 6.01 0 0 1-1.757 4.243 6.01 6.01 0 0 1-4.245 1.759 5.995 5.995 0 0 1-4.245-1.756c-.921-.92-.327-2.35.876-2.848l3.368-1.396 3.368-1.396Z',
            feetAngleShape:
              'M268.328 97.093c4.904 4.471 5.685 14.204 5.684 16.927l-40.023-.019 34.339-16.909Z',
            feetAngleStroke: 'm234.245 113.391 61.231-29.888'
          }
        },
        low: {
          toesOff: {
            feet: 'M65.533 45.817c2.941-7.742-1.154-15.015-8.69-17.604-7.538-2.59-15.348 2.204-16.97 12.479-1.621 10.274-5.046 23.106-8.264 31.956a14.05 14.05 0 0 0-.793 3.344c-1.266 3.51-2.664 6.077-3.7 7.978a61.415 61.415 0 0 0-.814 1.529c-1.248 2.46-.757 7.397 1.269 9.557 2.025 2.16 7.07 3.915 10.648 4.371 3.578.456 11.294 2.89 15.367 6.942 4.075 4.053 8.544 7.972 14.525 6.29 1.03 0 2.089.003 3.151.006 5.907.018 11.921.036 13.783-.487 2.197-.618 3.647-3.666 1.746-5.809-1.433-1.615-4.07-2.256-6.423-2.828-.767-.187-1.504-.366-2.159-.569-2.664-.826-7.259-3.187-10.098-7.352-.7-1.027-1.859-2.534-3.236-4.326-3.562-4.632-8.586-11.168-10.937-16.233 1.603-4.948 4.828-12.723 7.616-19.447 1.6-3.855 3.055-7.365 3.98-9.797Z',
            mask_1:
              'm87.31 111.27-.196-1.002c-4.873.27-15.453.87-18.784 1.111-4.163.301-11.03-2.437-13.002-7.146-1.474-3.52-11.164-6.861-18.37-9.346-2.437-.84-4.59-1.583-6.045-2.2-2.59-1.102-3.736-5.06-4.048-8.253-.208.383-.397.737-.563 1.065-1.248 2.46-.757 7.397 1.269 9.557 2.025 2.16 7.07 3.915 10.648 4.371 3.578.456 11.294 2.89 15.367 6.942.305.303.611.612.92.923 3.823 3.85 8.018 8.074 13.552 6.518 3.724-1.047 8.568-1.137 12.224-1.205 2.216-.041 3.995-.074 4.824-.307.84-.236 1.64-.577 2.203-1.028Z',
            mask_2:
              'M45.3 84.45c1.136.637 1.556 2.128.534 2.932a6.001 6.001 0 0 1-9.669-5.424c.154-1.293 1.645-1.71 2.78-1.073l3.178 1.783 3.178 1.783ZM66.484 41.2c-.048 1.496-.355 3.047-.951 4.617-.924 2.432-2.38 5.941-3.979 9.797-2.788 6.723-6.013 14.5-7.616 19.447.705 1.519 1.65 3.17 2.725 4.863a58.435 58.435 0 0 0-1.998-.34c-2.482-.393-4.7-.744-5.172-2.106-1.956-5.66 1.698-20.605 6.97-32.69 1.926-4.418 6.441-5.475 10.021-3.588Z',
            feetAngleShape: 'M25 114.001c0-10.075-3.29-9.332 3.555-16.725L65 114.001H25Z',
            feetAngleStroke: 'M14.454 90.397 58 112'
          },
          heelStrike: {
            feet: 'M236.557 51.407c-1.761-8.093-9.165-11.955-16.898-10.014-7.734 1.94-11.665 10.22-7.416 19.716 4.25 9.496 8.384 22.119 10.518 31.292a14.014 14.014 0 0 0 1.159 3.232c.856 3.635 1.085 6.55 1.255 8.709.051.643.096 1.218.151 1.726.297 2.743 3.404 6.612 6.282 7.318 2.878.706 8.065-.575 11.315-2.145 3.249-1.569 11.047-3.738 16.675-2.564.421.088.846.18 1.275.272 5.307 1.141 11.13 2.394 14.921-1.929l11.346-12.938c1.504-1.716.854-4.656-1.909-5.415-1.603-.44-2.745.63-3.946 1.755-.869.814-1.769 1.657-2.896 1.977-2.685.76-7.827 1.288-12.481-.654-1.148-.479-2.942-1.11-5.075-1.86-5.515-1.94-13.296-4.678-18.032-7.64-1.357-5.02-2.899-13.298-4.231-20.456-.765-4.104-1.46-7.84-2.013-10.382Z',
            mask_1:
              'M287.908 91.601c-3.694 3.17-11.108 9.548-13.454 11.634-3.014 2.678-9.98 4.553-14.402 2.009-3.305-1.901-12.813 1.156-19.884 3.428-2.392.769-4.505 1.449-6.004 1.816-2.96.725-6.723-2.351-8.882-4.862.014.152.028.299.044.44.297 2.743 3.404 6.613 6.282 7.318 2.878.706 8.065-.575 11.314-2.144 3.25-1.57 11.047-3.739 16.676-2.565.42.088.846.18 1.275.272 5.307 1.142 11.13 2.394 14.921-1.929l11.345-12.938c.614-.7.869-1.603.769-2.479Z',
            mask_2:
              'M240.684 94.826c1.3-.086 2.466.934 2.048 2.166a6.004 6.004 0 0 1-11.069.727c-.576-1.167.446-2.33 1.746-2.415l3.637-.24 3.638-.238ZM234.836 47.021c.776 1.28 1.364 2.745 1.721 4.385.553 2.542 1.248 6.277 2.012 10.38 1.333 7.158 2.875 15.436 4.232 20.457 1.42.889 3.115 1.757 4.94 2.59-.629.255-1.254.534-1.862.805-2.296 1.025-4.348 1.94-5.486 1.056-4.73-3.676-9.825-18.194-12.003-31.197-.797-4.755 2.413-8.105 6.446-8.476Z',
            feetAngleShape: 'M273.466 109.232c.551 2.666.548 2.066.546 4.788l-40.023-.019 39.477-4.769Z',
            feetAngleStroke: 'm234.282 113.801 69.364-9.8'
          }
        },
        very_low: {
          toesOff: {
            feet: 'M65.533 45.817c2.941-7.742-1.154-15.015-8.69-17.604-7.538-2.59-15.348 2.204-16.97 12.479-1.621 10.274-5.046 23.106-8.264 31.956a14.05 14.05 0 0 0-.793 3.344c-1.266 3.51-2.664 6.077-3.7 7.978a61.415 61.415 0 0 0-.814 1.529c-1.248 2.46-.757 7.397 1.269 9.557 2.025 2.16 7.07 3.915 10.648 4.371 3.578.456 11.294 2.89 15.367 6.942 4.075 4.053 8.544 7.972 14.525 6.29 1.03 0 2.089.003 3.151.006 5.907.018 11.921.036 13.783-.487 2.197-.618 3.647-3.666 1.746-5.809-1.433-1.615-4.07-2.256-6.423-2.828-.767-.187-1.504-.366-2.159-.569-2.664-.826-7.259-3.187-10.098-7.352-.7-1.027-1.859-2.534-3.236-4.326-3.562-4.632-8.586-11.168-10.937-16.233 1.603-4.948 4.828-12.723 7.616-19.447 1.6-3.855 3.055-7.365 3.98-9.797Z',
            mask_1:
              'm87.31 111.27-.196-1.002c-4.873.27-15.453.87-18.784 1.111-4.163.301-11.03-2.437-13.002-7.146-1.474-3.52-11.164-6.861-18.37-9.346-2.437-.84-4.59-1.583-6.045-2.2-2.59-1.102-3.736-5.06-4.048-8.253-.208.383-.397.737-.563 1.065-1.248 2.46-.757 7.397 1.269 9.557 2.025 2.16 7.07 3.915 10.648 4.371 3.578.456 11.294 2.89 15.367 6.942.305.303.611.612.92.923 3.823 3.85 8.018 8.074 13.552 6.518 3.724-1.047 8.568-1.137 12.224-1.205 2.216-.041 3.995-.074 4.824-.307.84-.236 1.64-.577 2.203-1.028Z',
            mask_2:
              'M45.3 84.45c1.136.637 1.556 2.128.534 2.932a6.001 6.001 0 0 1-9.669-5.424c.154-1.293 1.645-1.71 2.78-1.073l3.178 1.783 3.178 1.783ZM66.484 41.2c-.048 1.496-.355 3.047-.951 4.617-.924 2.432-2.38 5.941-3.979 9.797-2.788 6.723-6.013 14.5-7.616 19.447.705 1.519 1.65 3.17 2.725 4.863a58.435 58.435 0 0 0-1.998-.34c-2.482-.393-4.7-.744-5.172-2.106-1.956-5.66 1.698-20.605 6.97-32.69 1.926-4.418 6.441-5.475 10.021-3.588Z',
            feetAngleShape: 'M25 114.001c0-10.075-3.29-9.332 3.555-16.725L65 114.001H25Z',
            feetAngleStroke: 'M14.454 90.397 58 112'
          },
          heelStrike: {
            feet: 'M236.557 51.407c-1.761-8.093-9.165-11.955-16.898-10.014-7.734 1.94-11.665 10.22-7.416 19.716 4.25 9.496 8.384 22.119 10.518 31.292a14.014 14.014 0 0 0 1.159 3.232c.856 3.635 1.085 6.55 1.255 8.709.051.643.096 1.218.151 1.726.297 2.743 3.404 6.612 6.282 7.318 2.878.706 8.065-.575 11.315-2.145 3.249-1.569 11.047-3.738 16.675-2.564.421.088.846.18 1.275.272 5.307 1.141 11.13 2.394 14.921-1.929l11.346-12.938c1.504-1.716.854-4.656-1.909-5.415-1.603-.44-2.745.63-3.946 1.755-.869.814-1.769 1.657-2.896 1.977-2.685.76-7.827 1.288-12.481-.654-1.148-.479-2.942-1.11-5.075-1.86-5.515-1.94-13.296-4.678-18.032-7.64-1.357-5.02-2.899-13.298-4.231-20.456-.765-4.104-1.46-7.84-2.013-10.382Z',
            mask_1:
              'M287.908 91.601c-3.694 3.17-11.108 9.548-13.454 11.634-3.014 2.678-9.98 4.553-14.402 2.009-3.305-1.901-12.813 1.156-19.884 3.428-2.392.769-4.505 1.449-6.004 1.816-2.96.725-6.723-2.351-8.882-4.862.014.152.028.299.044.44.297 2.743 3.404 6.613 6.282 7.318 2.878.706 8.065-.575 11.314-2.144 3.25-1.57 11.047-3.739 16.676-2.565.42.088.846.18 1.275.272 5.307 1.142 11.13 2.394 14.921-1.929l11.345-12.938c.614-.7.869-1.603.769-2.479Z',
            mask_2:
              'M240.684 94.826c1.3-.086 2.466.934 2.048 2.166a6.004 6.004 0 0 1-11.069.727c-.576-1.167.446-2.33 1.746-2.415l3.637-.24 3.638-.238ZM234.836 47.021c.776 1.28 1.364 2.745 1.721 4.385.553 2.542 1.248 6.277 2.012 10.38 1.333 7.158 2.875 15.436 4.232 20.457 1.42.889 3.115 1.757 4.94 2.59-.629.255-1.254.534-1.862.805-2.296 1.025-4.348 1.94-5.486 1.056-4.73-3.676-9.825-18.194-12.003-31.197-.797-4.755 2.413-8.105 6.446-8.476Z',
            feetAngleShape: 'M273.466 109.232c.551 2.666.548 2.066.546 4.788l-40.023-.019 39.477-4.769Z',
            feetAngleStroke: 'm234.282 113.801 69.364-9.8'
          }
        }
      };
    });

    const unit = computed(() => getUnit(EValueTypeUnit.Clearance));

    return {
      // Values
      data,
      angles,
      unit
    };
  }
});
