











// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRunningSpeedCadenceDetailledExport from '@/components/charts/running/ChartRunningSpeedCadenceDetailledExport.vue';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameRunningChartSpeedCadence',
  components: {
    ChartRunningSpeedCadenceDetailledExport
  },
  props: {
    hideTitle: {
      type: Boolean
    },
    segments: {
      type: Object,
      required: true
    },
    exportSelectedSegments: {
      type: Array,
      required: true
    }
  },
  setup() {
    const { isImperial } = useUnit().currentUnit();

    return {
      isImperial
    };
  }
});
