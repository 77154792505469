







































































































// Import vendors ----------------------------------------------------------------------------------
import {
  defineComponent,
  ref,
  reactive,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  Ref
} from '@vue/composition-api';
import { cloneDeep, get, isArray, isPlainObject, mergeWith } from 'lodash';
import { useLocalStorage } from '@vueuse/core';
// Import components -------------------------------------------------------------------------------
import PatientEvolutionHeader from '@/components/patient/PatientEvolutionHeader.vue';
import ChartEvolutionGroupsWalking from '@/components/charts/evolution/groups/ChartEvolutionGroupsWalking.vue';
import ChartEvolutionGroupsWalkingCustom from '@/components/charts/evolution/groups/ChartEvolutionGroupsWalkingCustom.vue';
import ChartEvolutionGroupsRunning from '@/components/charts/evolution/groups/ChartEvolutionGroupsRunning.vue';
import ChartEvolutionGroupsRehabCMJ from '@/components/charts/evolution/groups/ChartEvolutionGroupsRehabCMJ.vue';
import ChartEvolutionGroupsRehabTripleHop from '@/components/charts/evolution/groups/ChartEvolutionGroupsRehabTripleHop.vue';
import ChartEvolutionGroupsRehabSideHop from '@/components/charts/evolution/groups/ChartEvolutionGroupsRehabSideHop.vue';
import ChartEvolutionGroupsRehabSingleHop from '@/components/charts/evolution/groups/ChartEvolutionGroupsRehabSingleHop.vue';
import AlertError from '@/components/alerts/AlertError.vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
// Import config -----------------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// -------------------------------------------------------------------------------------------------

export type TDataStandard = {
  value: TDataValue;
};
export type TDataValue = {
  left: TDataSide;
  right: TDataSide;
};
export type TDataSide = {
  values: number[];
  ranges: number[][];
};

export enum EFilter {
  Default = 'default',
  Neurological = 'neurological',
  Traumatology = 'traumatology',
  Reathleticism = 're-athleticism'
}

enum EScenarioKey {
  WalkingEmbedded = 'walking_embedded',
  RunningEmbedded = 'running_embedded'
}

enum EAnalysisEnvironementType {
  DSPro = 'dspro',
  PodoSmart = 'podosmart',
  Default = 'default'
}

export default defineComponent({
  name: 'PatientEvolution',
  components: {
    PatientEvolutionHeader,
    ChartEvolutionGroupsWalking,
    ChartEvolutionGroupsWalkingCustom,
    ChartEvolutionGroupsRunning,
    ChartEvolutionGroupsRehabCMJ,
    ChartEvolutionGroupsRehabTripleHop,
    ChartEvolutionGroupsRehabSideHop,
    ChartEvolutionGroupsRehabSingleHop,
    AlertError
  },
  setup() {
    const { data: patient } = usePatient();
    const busModule = usePodocoreModule('bus');

    const scenarioKey = ref(EScenarioKey.WalkingEmbedded);
    const walkingType = ref(EAnalysisEnvironementType.Default);
    const runningType = ref(EAnalysisEnvironementType.Default);
    const customView = computed(
      () => selectedFilter.value !== EFilter.Default || customSelectedCharts.value.length
    );
    const relative = ref(false);
    const hideDegradedAnalyses = useLocalStorage('evolution:hide-degraded-analyses', true, {
      listenToStorageChanges: true
    });

    const requestModule = usePodocoreModule('request');

    const evolutionRequest: any = ref(null);

    const evolutionData = computed(() => {
      if (evolutionRequest.value?.data) {
        // Clone to avoid mutating the original data
        const clonedData = cloneDeep(evolutionRequest.value.data);

        let baseEvolution = clonedData[0];

        if (clonedData.length > 1) {
          const subparts = clonedData.map(({ analysisManifests, data }: any) => ({
            analysisManifests,
            data
          }));

          const subpartsMerge = mergeWith(subparts[0], ...subparts.slice(1), mergeWithProcessor);

          const fragmentExtractor = (source: any, index: number): any => {
            return Object.entries(source).reduce((accumulator, [key, value]: any) => {
              if (isPlainObject(value)) {
                (accumulator as any)[String(key)] = fragmentExtractor(value, index);
              } else {
                (accumulator as any)[String(key)] = [value[Number(index)]];
              }

              return accumulator;
            }, {} as any);
          };

          const fragments = subpartsMerge.analysisManifests.reduce(
            (accumulator: any, analysisManifest: any, index: number) => {
              accumulator.push({
                analysisManifest,
                data: fragmentExtractor(subpartsMerge.data, index)
              });
              return accumulator;
            },
            []
          );

          const sortedFragments = fragments.sort((a: any, b: any) => {
            return (
              new Date(a.analysisManifest.createdAt).getTime() -
              new Date(b.analysisManifest.createdAt).getTime()
            );
          });

          const condensateFragments = sortedFragments.reduce(
            (accumulator: any, { analysisManifest, data }: any, index: number) => {
              accumulator.analysisManifests.push(analysisManifest);
              if (index === 0) accumulator.data = data;
              else mergeWith(accumulator.data, data, mergeWithProcessor);
              return accumulator;
            },
            {
              analysisManifests: [],
              data: {} as any
            } as any
          );
          return Object.assign({}, baseEvolution, condensateFragments);
        }
        return baseEvolution;
      }
      return undefined;
    });

    const degradedAnalysesIndexes = computed(() => {
      if (!evolutionData.value) return [];
      const result = [];
      for (let index = 0; index < evolutionData.value.analysisManifests.length; index++) {
        if (evolutionData.value.analysisManifests[Number(index)].analysis_validity === 1) result.push(index);
      }
      return result;
    });

    const onlyDegradedAnalyses = computed(() => {
      return degradedAnalysesIndexes.value.length === evolutionData.value.analysisManifests.length;
    });

    const mixEnvironnementAnalysisStatus = reactive({
      walking: false,
      running: false
    });

    const haveMixEnvironnementAnalysis = computed(() => {
      if (!evolutionData.value) return false;

      let haveDSProAnalysis = false;
      let havePodosmartAnalysis = false;
      for (let index = 0; index < evolutionData.value.analysisManifests.length; index++) {
        if (
          evolutionData.value.analysisManifests[Number(index)].podomigration &&
          !evolutionData.value.analysisManifests[Number(index)].isDeleted
        )
          havePodosmartAnalysis = true;
        if (
          !evolutionData.value.analysisManifests[Number(index)].podomigration &&
          !evolutionData.value.analysisManifests[Number(index)].isDeleted
        )
          haveDSProAnalysis = true;
      }
      return havePodosmartAnalysis && haveDSProAnalysis;
    });

    const sanitizedEvolutionData = computed(() => {
      if (!evolutionData.value) return undefined;

      const clonedBaseEvolution = cloneDeep(evolutionData.value);

      let deletionCounter = 0;

      const removeNested = (data: any, _index: number) => {
        let index = _index;
        if (index < 0) index = 0;
        let _data: any = data;

        if (isPlainObject(data)) {
          Object.keys(data).forEach((key: string) => {
            removeNested(data[String(key)], index);
          });
        } else if (isArray(data)) {
          _data = _data.splice(index, 1);
        }

        clonedBaseEvolution.data = _data;
      };

      const removeAnalysis = (index: number) => {
        removeNested(clonedBaseEvolution.data, index - deletionCounter);
        clonedBaseEvolution.analysisManifests.splice(index - deletionCounter, 1);
        deletionCounter++;
      };

      for (let index = 0; index < evolutionData.value.analysisManifests.length; index++) {
        // IS DELETED
        if (evolutionData.value.analysisManifests[Number(index)].isDeleted) {
          removeAnalysis(index);
        }
      }

      for (let index = 0; index < clonedBaseEvolution.analysisManifests.length; index++) {
        // DEGRADED
        if (scenarioKey.value === EScenarioKey.WalkingEmbedded && degradedAnalysesIndexes.value.length) {
          degradedAnalysesIndexes.value.forEach((index) => {
            removeAnalysis(index);
          });
        }

        // DSPRO
        if (
          ((scenarioKey.value === EScenarioKey.WalkingEmbedded &&
            walkingType.value === EAnalysisEnvironementType.DSPro) ||
            (scenarioKey.value === EScenarioKey.RunningEmbedded &&
              runningType.value === EAnalysisEnvironementType.DSPro)) &&
          !!evolutionData.value.analysisManifests[Number(index)].podomigration
        ) {
          removeAnalysis(index);
        }

        // PODOSMART
        if (
          ((scenarioKey.value === EScenarioKey.WalkingEmbedded &&
            walkingType.value === EAnalysisEnvironementType.PodoSmart) ||
            (scenarioKey.value === EScenarioKey.RunningEmbedded &&
              runningType.value === EAnalysisEnvironementType.PodoSmart)) &&
          !evolutionData.value.analysisManifests[Number(index)].podomigration
        ) {
          removeAnalysis(index);
        }
      }

      return clonedBaseEvolution;
    });

    function mergeWithProcessor<T, S>(objectValue: T, sourceValue: S) {
      if (isArray(objectValue)) {
        return objectValue.concat(sourceValue);
      }
    }

    function launchEvolutionRequest(scenarioKey: string) {
      evolutionRequest.value = requestModule.useAuthenticatedRequest(`${apiConfig.default}/evolutions`, {
        axios: {
          params: {
            patientCuid: patient.value?.cuid,
            scenarioKey: scenarioKey.startsWith('jumping') ? scenarioKey : scenarioKey.split('_')[0],
            scenarioKeyIsRegex: true
          }
        }
      });
      evolutionRequest.value?.request();
    }

    const evolutionIsNotFound = computed(() => {
      return get(evolutionRequest.value, 'error.response.data.message') === 'evolution not found';
    });

    function setScenarioKey(_scenarioKey: EScenarioKey) {
      scenarioKey.value = _scenarioKey;
    }
    function setWalkingType(_walkingType: EAnalysisEnvironementType) {
      walkingType.value = _walkingType;
    }
    function setRunningType(_runningType: EAnalysisEnvironementType) {
      runningType.value = _runningType;
    }
    function setRelativeMode(_relative: boolean) {
      relative.value = _relative;
    }

    function toggleHideDegradedAnalyses(value: boolean) {
      hideDegradedAnalyses.value = !value;
    }

    // Walking custom charts
    const customSelectedCharts = useLocalStorage('customSelectedCharts', []);
    function setSelectedCharts(data: any) {
      customSelectedCharts.value = data;
    }
    const selectedFilter: Ref<string> = useLocalStorage('selectedFilter', EFilter.Default);
    function setSelectedFilter(filter: string) {
      selectedFilter.value = filter;
    }

    function openExport() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-export-pdf',
          meta: {
            scenario: scenarioKey.value,
            analyses: sanitizedEvolutionData.value,
            patient: patient.value,
            mode: 'evolution'
          }
        })
      );
    }

    watch(haveMixEnvironnementAnalysis, (value) => {
      if (value === true && walkingType.value === EAnalysisEnvironementType.Default) {
        walkingType.value = EAnalysisEnvironementType.DSPro;
      }
      if (value === true && runningType.value === EAnalysisEnvironementType.Default) {
        runningType.value = EAnalysisEnvironementType.DSPro;
      }
      if (scenarioKey.value === EScenarioKey.WalkingEmbedded && value === true) {
        mixEnvironnementAnalysisStatus.walking = true;
      }
      if (scenarioKey.value === EScenarioKey.RunningEmbedded && value === true) {
        mixEnvironnementAnalysisStatus.running = true;
      }
    });

    watch(scenarioKey, (key) => {
      launchEvolutionRequest(key);
    });

    watch(walkingType, () => {
      launchEvolutionRequest(scenarioKey.value);
    });
    watch(runningType, () => {
      launchEvolutionRequest(scenarioKey.value);
    });

    onMounted(() => {
      launchEvolutionRequest(scenarioKey.value);
    });

    onBeforeUnmount(() => {
      evolutionRequest.value?.cancel();
    });

    return {
      runningType,
      walkingType,
      // Requests
      evolutionRequest,
      // Values
      scenarioKey,
      evolutionData,
      sanitizedEvolutionData,
      relative,
      degradedAnalysesIndexes,
      onlyDegradedAnalyses,
      mixEnvironnementAnalysisStatus,
      haveMixEnvironnementAnalysis,
      hideDegradedAnalyses,
      customSelectedCharts,
      selectedFilter,
      // Flags
      evolutionIsNotFound,
      customView,
      // Banner event
      setScenarioKey,
      setWalkingType,
      setRunningType,
      setRelativeMode,
      toggleHideDegradedAnalyses,
      setSelectedCharts,
      setSelectedFilter,
      openExport
    };
  }
});
