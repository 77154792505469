

































import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ExportationFrameDoctor from '@/components/exportation/frames/ExportationFrameDoctor.vue';
import ExportationFramePatient from '@/components/exportation/frames/ExportationFramePatient.vue';
import ExportationFrameTitle from '@/components/exportation/frames/ExportationFrameTitle.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameHeader',
  components: {
    ExportationFrameDoctor,
    ExportationFramePatient,
    ExportationFrameTitle
  },
  props: {
    headerInformations: {
      type: Object,
      required: true
    }
  }
});
