














// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartWalkingGaitLine from '@/components/charts/walking/ChartWalkingGaitLine.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingChartGaitLine',
  components: {
    ChartWalkingGaitLine
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    patient: {
      type: Object,
      required: false,
      default: null
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    }
  },
  setup() {
    const opacity = 1;
    const width = 24;

    return {
      opacity,
      width
    };
  }
});
