













// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRunningStrikePatternAdvanced from '@/components/charts/running/ChartRunningStrikePatternAdvanced.vue';
import ChartRunningStrikePatternSimplified from '@/components/charts/running/ChartRunningStrikePatternSimplified.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameRunningChartStrikePattern',
  components: {
    ChartRunningStrikePatternAdvanced,
    ChartRunningStrikePatternSimplified
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    analysisDate: {
      type: String
    },
    hideTitle: {
      type: Boolean
    }
  },
  setup(properties) {
    const advanced = computed(
      () =>
        properties.displayMetrics.aggregates?.running_strike_pattern &&
        properties.displayMetrics.aggregates?.running_plantar_flexion_angle_foot_in &&
        properties.displayMetrics.aggregates?.running_impact_force
    );
    const simplified = computed(() => properties.displayMetrics.aggregates?.running_strike_pattern);
    const valid = computed(() => advanced.value || simplified.value);

    return {
      valid,
      advanced,
      simplified
    };
  }
});
