













// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionContainer from '@/components/charts/ChartEvolutionContainer.vue';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils - ----------------------------------------------------------------------------------
import { useChart, EThemeColor } from '@/utils/charts.utils';
import { EUnitKey } from '@/utils/parameter-units.utils';
// Import types ------------------------------------------------------------------------------------
import type { TDataStandard } from '@/components/patient/PatientEvolution.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionPropulsionRate',
  components: {
    ChartEvolutionContainer
  },
  props: {
    manifest: {
      type: Array,
      required: true
    },
    propulsionRatio: {
      type: Object
    },
    norms: {
      type: Object
    }
  },
  setup(properties) {
    const {
      evolutionChartStructure,
      getSideData,
      getSideDataVariations,
      getSerieItemStructure,
      getNormsStructure
    } = useChart();

    const title = 'commons.standards.propulsion-rate';

    const propulsionRatio: TDataStandard = computed(() => getSideData(properties.propulsionRatio));

    const dataVariations = computed(() =>
      getSideDataVariations({
        left: propulsionRatio.value?.left.values,
        right: propulsionRatio.value?.right.values
      })
    );

    const valid = computed(() => !!propulsionRatio.value);

    const series = computed(() => {
      let data = [
        // Left
        getSerieItemStructure(
          'propulsion_rate_left',
          `${i18n.t('commons.standards.propulsion-rate')} (${(
            i18n.t('commons.standards.left') as string
          ).slice(0, 1)})`,
          propulsionRatio.value?.left.values,
          EThemeColor.Chart1
        ),
        getSerieItemStructure(
          'propulsion_rate_left',
          undefined,
          propulsionRatio.value?.left.ranges,
          EThemeColor.Chart1,
          true
        ),
        // Right
        getSerieItemStructure(
          'propulsion_rate_right',
          `${i18n.t('commons.standards.propulsion-rate')} (${(
            i18n.t('commons.standards.right') as string
          ).slice(0, 1)})`,
          propulsionRatio.value?.right.values,
          EThemeColor.Chart2
        ),
        getSerieItemStructure(
          'propulsion_rate_right',
          undefined,
          propulsionRatio.value?.right.ranges,
          EThemeColor.Chart2,
          true
        )
      ];
      // Have norms
      if (properties.norms)
        data.push(
          getNormsStructure('propulsion_rate_norms', properties.norms as { min: number[]; max: number[] })
        );

      return data;
    });

    const chartOptions = computed(() => {
      return {
        ...evolutionChartStructure(
          'commons.standards.propulsion-rate',
          properties.manifest,
          EUnitKey.Percent,
          1
        ),
        series: series.value
      };
    });

    return {
      title,
      dataVariations,
      chartOptions,
      valid
    };
  }
});
