<template>
  <div class="chart-wrapper py-5">
    <img
      :src="cdn('image', `${chart.data[0].image || chartTitle}.svg`)"
      class="mx-auto"
      height="60"
      :alt="$t(`commons.standards.${chartTitle}`)"
    />
    <span class="chart-title">{{ $t(`commons.standards.${chart.title}`) }}</span>
    <div class="d-flex justify-space-between">
      <span class="data-title data-title--left">{{ $t('commons.standards.left').toUpperCase() }}</span>
      <span class="data-title data-title--right">
        {{ $t('commons.standards.right').toUpperCase() }}
      </span>
    </div>
    <div class="d-flex justify-space-between">
      <div class="d-flex flex-column">
        <VChip
          v-for="(data, index) in chart.data"
          :key="index"
          small
          color="gray"
          class="mb-1 font-weight-bold justify-center"
          style="padding: 0 8px"
        >
          {{ data.leftData }}{{ getSimplifiedUnit(data.unit) }}
        </VChip>
      </div>
      <div>
        <div
          v-for="(data, index) in chart.data"
          :key="index"
          class="d-flex justify-space-between align-center w-100 mb-1 mx-1"
        >
          <div
            class="rounded-chip rounded-chip--left font-weight-bold"
            :class="{ 'opacity-0': data.sideDiff !== 'left' }"
          >
            {{ $t('commons.standards.left').charAt(0) }}
          </div>
          <VChip color="black" small class="white--text mx-1 font-weight-bold px-2">
            {{ data.diffData }}{{ getSimplifiedUnit(data.unit) }}
          </VChip>
          <div
            class="rounded-chip rounded-chip--right font-weight-bold"
            :class="{ 'opacity-0': data.sideDiff !== 'right' }"
          >
            {{ $t('commons.standards.right').charAt(0) }}
          </div>
        </div>
      </div>
      <div class="d-flex flex-column">
        <VChip
          v-for="(data, index) in chart.data"
          :key="index"
          small
          color="gray"
          class="mb-1 font-weight-bold justify-center"
          style="padding: 0 8px"
        >
          {{ data.rightData }}{{ getSimplifiedUnit(data.unit) }}
        </VChip>
      </div>
    </div>
  </div>
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
import { mean } from 'lodash';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
import { useRehab } from '@/utils/rehab.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabGeneralParameters',
  props: {
    chart: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { cdn } = useCDN();
    const { getSimplifiedUnit } = useRehab();
    const chartTitle = computed(() => {
      if (properties.chart.title !== 'flexion-angle-at-landing') return properties.chart.title;
      else {
        const left = parseInt(properties.chart.data[0].leftData.toString().replace('°', ''));
        const right = parseInt(properties.chart.data[0].rightData.toString().replace('°', ''));

        const avgValue = mean([left, right]);

        if (avgValue < -5) return 'flexion-angle-at-landing';
        else if (avgValue <= 5) return 'flat-foot';
        else return 'flexion-angle-at-take-off';
      }
    });
    return {
      // Methods
      cdn,
      getSimplifiedUnit,
      chartTitle
    };
  }
});
</script>

<style lang="scss" scoped>
.chart-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 14rem;
}

.chart-title {
  font-weight: 600;
  font-size: 0.8rem;
  color: #020c27;
  text-align: center;
}

.data-title {
  font-weight: 600;
  font-size: 0.8rem;

  &--left {
    color: #b298dc;
  }

  &--right {
    color: #00a0d1;
  }
}

.rounded-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  height: 1.25rem;
  width: 1.25rem;
  font-size: 0.75rem;
  line-height: 1rem;

  &--left {
    background-color: #b298dc;
  }

  &--right {
    background-color: #00a0d1;
  }
}

.theme--light.v-chip:not(.v-chip--active) {
  background: #fafafa;
}
</style>
