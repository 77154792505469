import i18n from '@/plugins/i18n';
import { DrawTooltip } from './angles-prosup-walking-tooltips';
import { getBlockMiddle, getPercentPos } from '@/helpers/helped-chart-functions.helper';

export enum ESide {
  left = 'left',
  right = 'right'
}
export type CONTAINER = {
  sideLegend: {
    left: POSITION;
    right: POSITION;
  };
  middle: number;
};
export type POSITION = {
  startX: number;
  endX: number;
};

export class DrawAnglesChartSVG {
  // Val
  private readonly canvas: HTMLCanvasElement;
  private readonly tooltipcanvas: HTMLCanvasElement;
  private readonly ctx: CanvasRenderingContext2D;
  private tooltipGenerator: any;

  private readonly width: number;
  private readonly height: number;

  private readonly container: any = {
    sideLegend: {
      left: {
        startX: 0,
        endX: 0
      },
      right: {
        startX: 0,
        endX: 0
      }
    },
    chart: {
      height: 0,
      width: 0,
      paddingTop: 80,
      paddingBottom: 40,
      scale: {
        min: -30,
        max: 10,
        zones: [0, 10, 20, 30, 40, 50]
      }
    },
    middle: 0
  };

  private readonly corridor: any;

  private readonly tooltips: any = {
    pos: {
      toesOff: {
        left: {
          x: 0,
          y: 0
        },
        right: {
          x: 0,
          y: 0
        }
      },
      heelOff: {
        left: {
          x: 0,
          y: 0
        },
        right: {
          x: 0,
          y: 0
        }
      },
      flatFoot: {
        left: {
          x: 0,
          y: 0
        },
        right: {
          x: 0,
          y: 0
        }
      },
      heelStrike: {
        left: {
          x: 0,
          y: 0
        },
        right: {
          x: 0,
          y: 0
        }
      }
    }
  };

  private values: any;

  private readonly mode: any;
  private readonly unit: string;

  private posY: any = {
    left: {
      toesOff: 0,
      heelOff: 0,
      flatFoot: 0,
      heelStrike: 0
    },
    right: {
      toesOff: 0,
      heelOff: 0,
      flatFoot: 0,
      heelStrike: 0
    }
  };

  constructor(
    canvas: HTMLCanvasElement,
    tooltipcanvas: HTMLCanvasElement,
    parentWidth: number,
    values: any,
    corridor: any,
    mode: any
  ) {
    this.canvas = canvas;
    this.tooltipcanvas = tooltipcanvas;
    this.ctx = canvas.getContext('2d')!;

    this.width = parentWidth;
    this.height = this.canvas.height;

    this.mode = mode;
    this.corridor = corridor;
    this.values = values;

    this.unit = mode === 'abs' ? 'ms' : '%';

    // Define chart positions
    this.container.sideLegend.left.endX = this.width * 0.2;
    this.container.sideLegend.right.startX = this.width * 0.8;
    this.container.sideLegend.right.endX = this.width;
    this.container.chart.width = this.width * 0.6;
    this.container.chart.height =
      this.height - this.container.chart.paddingTop - this.container.chart.paddingBottom;
    this.container.middle = this.width / 2;

    this.getTooltipZonePostion();
    this.getYPositionOfWalkPhases();
    this.getChartScale();
  }

  async draw() {
    this.clean();

    this.walkPhases();
    this.angleRangesLines();
    this.contentStructure();
    this.angleCorridor();
    this.angleStdLines();
    this.angleLinesAndPoints();

    this.tooltipGenerator = new DrawTooltip(
      this.tooltipcanvas,
      this.container,
      this.values,
      this.corridor,
      this.tooltips.pos,
      this.mode
    );
    requestAnimationFrame(() => {
      this.tooltipGenerator.drawTooltip();
    });
  }

  // Structure
  contentStructure() {
    // Globals
    const startY = this.container.chart.paddingTop - 10;
    const endY = this.container.chart.height + this.container.chart.paddingTop + 10;
    const startYLetter = this.container.chart.paddingTop - 20;
    const supPadding = 12;
    const imgScale = 40;
    const images: any = {
      left: {
        img: new Image(),
        posX: this.container.sideLegend.left.endX + this.container.chart.width / 4 - imgScale / 2
      },
      right: {
        img: new Image(),
        posX: this.container.sideLegend.right.startX - this.container.chart.width / 4 + imgScale / 2
      },
      width: 40,
      height: 35
    };
    //
    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = '#A2ABC2';
    this.ctx.fillStyle = '#A2ABC2';
    this.ctx.font = '600 12px Montserrat';

    // --- LEFT --- //

    // text
    this.ctx.textAlign = 'left';
    this.ctx.fillText(
      i18n.t('commons.standards.supinator') as string,
      this.container.sideLegend.left.endX - supPadding,
      startYLetter
    );
    // bar
    this.ctx.beginPath();
    this.ctx.moveTo(this.container.sideLegend.left.endX, startY);
    this.ctx.lineTo(this.container.sideLegend.left.endX, endY);
    this.ctx.stroke();

    // --- MIDDLE --- //

    // text
    this.ctx.textAlign = 'center';
    this.ctx.fillText(i18n.t('commons.standards.pronator') as string, this.container.middle, startYLetter);
    // bar
    this.ctx.beginPath();
    this.ctx.moveTo(this.container.middle, startY);
    this.ctx.lineTo(this.container.middle, endY);
    this.ctx.stroke();

    // --- RIGHT --- //

    // text
    this.ctx.textAlign = 'right';
    this.ctx.fillText(
      i18n.t('commons.standards.supinator') as string,
      this.container.sideLegend.right.startX + supPadding,
      startYLetter
    );
    // bar
    this.ctx.beginPath();
    this.ctx.moveTo(this.container.sideLegend.right.startX, startY);
    this.ctx.lineTo(this.container.sideLegend.right.startX, endY);
    this.ctx.stroke();

    // --- LEGEND --- //
    this.ctx.font = '400 11px Montserrat';
    this.ctx.fillStyle = '#8e919a';
    this.ctx.textAlign = 'center';

    // left
    this.ctx.fillText(
      (i18n.t(`commons.standards.left`) as string).toUpperCase(),
      images.left.posX + imgScale / 2,
      startYLetter
    );

    // right
    this.ctx.fillText(
      (i18n.t(`commons.standards.right`) as string).toUpperCase(),
      images.right.posX - imgScale / 2,
      startYLetter
    );
  }

  // Vertical bar for angles
  angleRangesLines() {
    // Globals
    const singleChartScale =
      (Math.abs(this.container.chart.scale.max) + Math.abs(this.container.chart.scale.min)) / 4;
    const singleChartWidth = this.container.chart.width / 2;
    const singleChartWidthSection = singleChartWidth / 4;
    const paddingY = this.container.chart.height + this.container.chart.paddingTop + 20;
    const paddingX = {
      left: this.container.sideLegend.left.endX,
      right: this.container.sideLegend.left.endX + singleChartWidth
    };
    const ranges: any = {
      left: {
        low: {
          posX: paddingX.left + singleChartWidthSection,
          value: this.container.chart.scale.max - singleChartScale * 3
        },
        neutral: {
          posX: paddingX.left + singleChartWidthSection * 2,
          value: this.container.chart.scale.max - singleChartScale * 2
        },
        high: {
          posX: paddingX.left + singleChartWidthSection * 3,
          value: this.container.chart.scale.max - singleChartScale
        }
      },
      right: {
        high: {
          posX: paddingX.right + singleChartWidthSection,
          value: this.container.chart.scale.max - singleChartScale
        },
        neutral: {
          posX: paddingX.right + singleChartWidthSection * 2,
          value: this.container.chart.scale.max - singleChartScale * 2
        },
        low: {
          posX: paddingX.right + singleChartWidthSection * 3,
          value: this.container.chart.scale.max - singleChartScale * 3
        }
      }
    };
    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = 'rgba(158,166,190,0.41)';
    this.ctx.setLineDash([5, 5]);

    for (const side of ['left', 'right']) {
      for (const range of ['high', 'neutral', 'low']) {
        // bar line
        this.ctx.beginPath();
        this.ctx.moveTo(ranges[side][range].posX, this.container.chart.paddingTop);
        this.ctx.lineTo(
          ranges[side][range].posX,
          this.container.chart.height + this.container.chart.paddingTop
        );
        this.ctx.stroke();

        this.ctx.font = '300 12px Montserrat';
        this.ctx.textAlign = 'center';
        this.ctx.fillStyle = '#717788';
        this.ctx.fillText(
          `${Number.parseFloat(ranges[side][range].value)} °`,
          ranges[side][range].posX,
          paddingY
        );
      }
    }

    this.ctx.setLineDash([]);
  }

  // Walk phase values
  walkPhases() {
    // Globals
    const singleChartWidth = this.container.chart.width / 2;
    const paddingTextY = 3;
    const startX: any = {
      line: {
        left: this.container.sideLegend.left.endX - 10,
        right: this.container.middle
      },
      background: {
        left: this.container.sideLegend.left.endX,
        right: this.container.middle
      },
      legend: {
        left: this.container.sideLegend.left.endX - 20,
        right: this.container.sideLegend.right.startX + 20
      },
      value: {
        left: this.container.sideLegend.left.endX - 45,
        right: this.container.sideLegend.right.startX + 45
      }
    };
    const endX: any = {
      line: {
        left: this.container.middle,
        right: this.container.sideLegend.right.startX + 10
      },
      background: {
        left: singleChartWidth,
        right: singleChartWidth
      }
    };

    for (const side of ['left', 'right']) {
      this.ctx.textAlign = side === 'left' ? 'right' : 'left';

      this.footStepsAngles(side, startX, endX, paddingTextY);
      this.footStepsPhases(side, startX, endX, paddingTextY);
    }
  }

  // Heel strike - Toes strike - Heel off - Toes off
  footStepsAngles(side: string, startX: any, endX: any, paddingTextY: number) {
    const angleNames: any = {
      toesOff: i18n.t('commons.standards.walk.toe-off') as string,
      heelOff: i18n.t('commons.standards.walk.heel-off') as string,
      flatFoot: i18n.t('commons.standards.walk.flat-foot') as string,
      heelStrike: i18n.t('commons.standards.walk.heel-strike') as string
    };
    // --- BAR LINE --- \\
    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = '#F3F6FC';
    this.ctx.font = '300 12px Montserrat';
    this.ctx.fillStyle = '#A2ABC2';

    for (const angle of ['toesOff', 'heelOff', 'flatFoot', 'heelStrike']) {
      this.ctx.setLineDash([]);
      this.ctx.beginPath();
      this.ctx.moveTo(startX.line[side], this.posY[side][angle]);
      this.ctx.lineTo(endX.line[side], this.posY[side][angle]);
      this.ctx.stroke();
      // ---
      const currentText = angleNames[angle];
      if (this.ctx.measureText(currentText).width > 120) {
        this.cutLargeText(currentText, startX.legend[side], this.posY[side][angle] + paddingTextY);
      } else {
        this.ctx.fillText(angleNames[angle], startX.legend[side], this.posY[side][angle] + paddingTextY);
      }
    }
  }

  // Taligrade - Plantigrade - Digitigrade
  footStepsPhases(side: string, startX: any, endX: any, paddingTextY: number) {
    const that: any = this;
    const valueRect: any = {
      width: 50,
      height: 23,
      startX: startX.value[side] - 25,
      paddingTopY: -15.5
    };
    const paddingXImg: number = (this.container.chart.width / 2) * 0.05;
    const phases: any = {
      digitigrade: {
        backgroundcolor: '#F3F6FC',
        color: '#F3F6FC',
        firstPosY: this.posY[side].toesOff,
        lastPosY: this.posY[side].heelOff,
        img: new Image(),
        imgSrc: require('@/assets/images/propulsion.svg')
      },
      plantigrade: {
        backgroundcolor: '#FCFDFF',
        color: '#FCFDFF',
        firstPosY: this.posY[side].heelOff,
        lastPosY: this.posY[side].flatFoot,
        img: new Image(),
        imgSrc: require('@/assets/images/flat-foot.svg')
      },
      taligrade: {
        backgroundcolor: '#F3F6FC',
        color: '#F3F6FC',
        firstPosY: this.posY[side].flatFoot,
        lastPosY: this.posY[side].heelStrike,
        img: new Image(),
        imgSrc: require('@/assets/images/heel-strike.svg')
      }
    };
    const chevronsPos: any = {
      left: valueRect.startX - 10,
      right: valueRect.startX + valueRect.width,
      up: -4,
      down: 4,
      posY: {
        up: -2,
        down: -5
      }
    };

    // --- PHASES --- \\
    this.ctx.font = '600 12px Montserrat';
    this.ctx.textAlign = 'center';

    for (const type of ['digitigrade', 'plantigrade', 'taligrade']) {
      const width = this.values[type][side][this.mode].indicators.chevron
        ? valueRect.width + 18
        : valueRect.width;
      const padding = this.values[type][side][this.mode].indicators.chevron && side === 'left' ? 18 : 0;

      this.ctx.fillStyle = phases[type].backgroundcolor;
      // Background
      this.ctx.beginPath();
      this.ctx.rect(
        startX.background[side],
        phases[type].firstPosY,
        endX.background[side],
        phases[type].lastPosY - phases[type].firstPosY
      );
      this.ctx.fill();
      this.ctx.fillStyle = this.values[type][side][this.mode].indicators.background;
      // Value
      this.ctx.beginPath();
      this.ctx.rect(
        valueRect.startX - padding,
        getBlockMiddle(phases[type].firstPosY, phases[type].lastPosY, paddingTextY) + valueRect.paddingTopY,
        width,
        valueRect.height
      );
      this.ctx.fill();
      this.ctx.fillStyle = this.values[type][side][this.mode].indicators.text;
      this.ctx.fillText(
        `${this.values[type][side][this.mode].value}${this.unit}`,
        startX.value[side],
        getBlockMiddle(phases[type].firstPosY, phases[type].lastPosY, paddingTextY)
      );
      // Chevron
      if (this.values[type][side][this.mode].indicators.chevron) {
        this.ctx.lineWidth = 2;
        this.ctx.strokeStyle = this.values[type][side][this.mode].indicators.text;
        this.ctx.beginPath();
        this.ctx.moveTo(
          chevronsPos[side],
          getBlockMiddle(phases[type].firstPosY, phases[type].lastPosY, paddingTextY) +
            chevronsPos.posY[this.values[type][side][this.mode].indicators.chevron]
        );
        this.ctx.lineTo(
          chevronsPos[side] + 4,
          getBlockMiddle(phases[type].firstPosY, phases[type].lastPosY, paddingTextY) +
            chevronsPos.posY[this.values[type][side][this.mode].indicators.chevron] +
            chevronsPos[this.values[type][side][this.mode].indicators.chevron]
        );
        this.ctx.lineTo(
          chevronsPos[side] + 8,
          getBlockMiddle(phases[type].firstPosY, phases[type].lastPosY, paddingTextY) +
            chevronsPos.posY[this.values[type][side][this.mode].indicators.chevron]
        );
        this.ctx.stroke();
        this.ctx.lineWidth = 2;
        this.ctx.strokeStyle = '#9ea6be';
      }

      // Img
      if (side === 'left') {
        phases[type].img.addEventListener('load', () => {
          that.ctx!.drawImage(
            phases[type].img,
            that.container.sideLegend.left.endX + paddingXImg,
            getBlockMiddle(phases[type].firstPosY, phases[type].lastPosY, -10)
          );
        });

        phases[type].img.src = phases[type].imgSrc;
      }
    }
  }

  // Angle ranges
  angleStdLines() {
    // Globals
    const singleChartWidth = this.container.chart.width / 2;
    const paddingX: any = {
      left: this.container.sideLegend.left.endX,
      right: this.container.sideLegend.left.endX + singleChartWidth
    };
    const reverse: any = {
      left: false,
      right: true
    };
    const values: any = {
      heelStrike: {
        left: {
          min: this.values.heelStrike.left[this.mode].stdMin,
          max: this.values.heelStrike.left[this.mode].stdMax,
          posY: this.posY.left.heelStrike
        },
        right: {
          min: this.values.heelStrike.right[this.mode].stdMin,
          max: this.values.heelStrike.right[this.mode].stdMax,
          posY: this.posY.right.heelStrike
        }
      },
      flatFoot: {
        left: {
          min: this.values.flatFoot.left[this.mode].stdMin,
          max: this.values.flatFoot.left[this.mode].stdMax,
          posY: this.posY.left.flatFoot
        },
        right: {
          min: this.values.flatFoot.right[this.mode].stdMin,
          max: this.values.flatFoot.right[this.mode].stdMax,
          posY: this.posY.right.flatFoot
        }
      },
      heelOff: {
        left: {
          min: this.values.heelOff.left[this.mode].stdMin,
          max: this.values.heelOff.left[this.mode].stdMax,
          posY: this.posY.left.heelOff
        },
        right: {
          min: this.values.heelOff.right[this.mode].stdMin,
          max: this.values.heelOff.right[this.mode].stdMax,
          posY: this.posY.right.heelOff
        }
      },
      toesOff: {
        left: {
          min: this.values.toesOff.left[this.mode].stdMin,
          max: this.values.toesOff.left[this.mode].stdMax,
          posY: this.posY.left.toesOff
        },
        right: {
          min: this.values.toesOff.right[this.mode].stdMin,
          max: this.values.toesOff.right[this.mode].stdMax,
          posY: this.posY.right.toesOff
        }
      }
    };

    // this.ctx!.setLineDash([0, 0]);
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = '#B7BFCF';

    for (const side of ['left', 'right']) {
      for (const level of ['min', 'max']) {
        this.ctx.beginPath();
        this.ctx.moveTo(
          paddingX[side] +
            getPercentPos(values.heelStrike[side][level], reverse[side], this.container.chart.scale) *
              singleChartWidth,
          values.heelStrike[side].posY
        );
        this.ctx.lineTo(
          paddingX[side] +
            getPercentPos(values.flatFoot[side][level], reverse[side], this.container.chart.scale) *
              singleChartWidth,
          values.flatFoot[side].posY
        );
        this.ctx.lineTo(
          paddingX[side] +
            getPercentPos(values.heelOff[side][level], reverse[side], this.container.chart.scale) *
              singleChartWidth,
          values.heelOff[side].posY
        );
        this.ctx.lineTo(
          paddingX[side] +
            getPercentPos(values.toesOff[side][level], reverse[side], this.container.chart.scale) *
              singleChartWidth,
          values.toesOff[side].posY
        );
        this.ctx.stroke();
      }
    }
    // this.ctx!.setLineDash([0, 0]);
  }

  // Angle values
  angleLinesAndPoints() {
    // Globals
    const singleChartWidth = this.container.chart.width / 2;
    const paddingX: any = {
      left: this.container.sideLegend.left.endX,
      right: this.container.sideLegend.left.endX + singleChartWidth
    };
    const reverse: any = {
      left: false,
      right: true
    };
    this.ctx.strokeStyle = '#67738F';
    this.ctx.fillStyle = '#67738F';

    for (const side of ['left', 'right']) {
      // Lines

      this.ctx.beginPath();
      this.ctx.moveTo(
        paddingX[side] +
          getPercentPos(this.values.toesOff[side][this.mode].avg, reverse[side], this.container.chart.scale) *
            singleChartWidth,
        this.posY[side].toesOff
      );
      this.ctx.lineTo(
        paddingX[side] +
          getPercentPos(this.values.heelOff[side][this.mode].avg, reverse[side], this.container.chart.scale) *
            singleChartWidth,
        this.posY[side].heelOff
      );
      this.ctx.lineTo(
        paddingX[side] +
          getPercentPos(
            this.values.flatFoot[side][this.mode].avg,
            reverse[side],
            this.container.chart.scale
          ) *
            singleChartWidth,
        this.posY[side].flatFoot
      );
      this.ctx.lineTo(
        paddingX[side] +
          getPercentPos(
            this.values.heelStrike[side][this.mode].avg,
            reverse[side],
            this.container.chart.scale
          ) *
            singleChartWidth,
        this.posY[side].heelStrike
      );
      this.ctx.stroke();

      // Points

      this.tooltips.pos.toesOff[side].x =
        paddingX[side] +
        getPercentPos(this.values.toesOff[side][this.mode].avg, reverse[side], this.container.chart.scale) *
          singleChartWidth;
      this.tooltips.pos.toesOff[side].y = this.posY[side].toesOff;
      this.ctx.beginPath();
      this.ctx.arc(this.tooltips.pos.toesOff[side].x, this.tooltips.pos.toesOff[side].y, 4, 0, 2 * Math.PI);
      this.ctx.fill();

      this.tooltips.pos.heelOff[side].x =
        paddingX[side] +
        getPercentPos(this.values.heelOff[side][this.mode].avg, reverse[side], this.container.chart.scale) *
          singleChartWidth;
      this.tooltips.pos.heelOff[side].y = this.posY[side].heelOff;
      this.ctx.beginPath();
      this.ctx.arc(this.tooltips.pos.heelOff[side].x, this.tooltips.pos.heelOff[side].y, 4, 0, 2 * Math.PI);
      this.ctx.fill();

      this.tooltips.pos.flatFoot[side].x =
        paddingX[side] +
        getPercentPos(this.values.flatFoot[side][this.mode].avg, reverse[side], this.container.chart.scale) *
          singleChartWidth;
      this.tooltips.pos.flatFoot[side].y = this.posY[side].flatFoot;
      this.ctx.beginPath();
      this.ctx.arc(this.tooltips.pos.flatFoot[side].x, this.tooltips.pos.flatFoot[side].y, 4, 0, 2 * Math.PI);
      this.ctx.fill();

      this.tooltips.pos.heelStrike[side].x =
        paddingX[side] +
        getPercentPos(
          this.values.heelStrike[side][this.mode].avg,
          reverse[side],
          this.container.chart.scale
        ) *
          singleChartWidth;
      this.tooltips.pos.heelStrike[side].y = this.posY[side].heelStrike;
      this.ctx.beginPath();
      this.ctx.arc(
        this.tooltips.pos.heelStrike[side].x,
        this.tooltips.pos.heelStrike[side].y,
        4,
        0,
        2 * Math.PI
      );
      this.ctx.fill();
    }
  }

  // Angle norms
  angleCorridor() {
    // Globals
    const singleChartWidth = this.container.chart.width / 2;
    const paddingX: any = {
      left: this.container.sideLegend.left.endX,
      right: this.container.sideLegend.left.endX + singleChartWidth
    };
    const reverse: any = {
      left: false,
      right: true
    };
    this.ctx.fillStyle = 'rgba(93,208,172,0.25)';

    if (this.mode === 'abs') {
      for (const side of ['left', 'right']) {
        this.ctx.beginPath();
        this.ctx.moveTo(
          paddingX[side] +
            getPercentPos(this.corridor.toesOff.min, reverse[side], this.container.chart.scale) *
              singleChartWidth,
          this.posY[side].toesOff
        );
        this.ctx.lineTo(
          paddingX[side] +
            getPercentPos(this.corridor.heelOff.min, reverse[side], this.container.chart.scale) *
              singleChartWidth,
          this.posY[side].heelOff
        );
        this.ctx.lineTo(
          paddingX[side] +
            getPercentPos(this.corridor.flatFoot.min, reverse[side], this.container.chart.scale) *
              singleChartWidth,
          this.posY[side].flatFoot
        );
        this.ctx.lineTo(
          paddingX[side] +
            getPercentPos(this.corridor.heelStrike.min, reverse[side], this.container.chart.scale) *
              singleChartWidth,
          this.posY[side].heelStrike
        );
        this.ctx.lineTo(
          paddingX[side] +
            getPercentPos(this.corridor.heelStrike.max, reverse[side], this.container.chart.scale) *
              singleChartWidth,
          this.posY[side].heelStrike
        );
        this.ctx.lineTo(
          paddingX[side] +
            getPercentPos(this.corridor.flatFoot.max, reverse[side], this.container.chart.scale) *
              singleChartWidth,
          this.posY[side].flatFoot
        );
        this.ctx.lineTo(
          paddingX[side] +
            getPercentPos(this.corridor.heelOff.max, reverse[side], this.container.chart.scale) *
              singleChartWidth,
          this.posY[side].heelOff
        );
        this.ctx.lineTo(
          paddingX[side] +
            getPercentPos(this.corridor.toesOff.max, reverse[side], this.container.chart.scale) *
              singleChartWidth,
          this.posY[side].toesOff
        );
        this.ctx.fill();
      }
    }
  }

  // Initialized data
  getTooltipZonePostion() {
    const zoneHeight = 30;
    let zones = {};
    for (const side of ['left', 'right']) {
      for (const type of ['toesOff', 'heelOff', 'flatFoot', 'heelStrike']) {
        zones = {
          ...zones,
          type: {}
        };
      }
    }
  }

  getYPositionOfWalkPhases() {
    const height = this.container.chart.height;
    for (const side of ['left', 'right']) {
      const digitigradeSize = height * (this.values.digitigrade[side].rel.value / 100);
      const taligradeSize = height * (this.values.taligrade[side].rel.value / 100);

      const toesOff = this.container.chart.paddingTop;
      const heelOff = this.container.chart.paddingTop + digitigradeSize;
      const flatFoot = this.container.chart.paddingTop + height - taligradeSize;
      const heelStrike = this.container.chart.height + this.container.chart.paddingTop;

      this.posY[side] = {
        toesOff,
        heelOff: this.getMinYPhasePosition('top', toesOff, heelOff, flatFoot),
        flatFoot: this.getMinYPhasePosition('bottom', flatFoot, heelStrike, heelOff),
        heelStrike
      };
    }
  }

  getMinYPhasePosition(type: string, firstValue: number, secondValue: number, thirdValue: number) {
    // If walking phases are too short
    const diff = secondValue - firstValue;
    const minSpace = 50;

    if (type === 'top') {
      // If the space between toesOff and heelOff is to short
      if (diff < minSpace) {
        return firstValue + minSpace;
      }

      // If the space between heelOff and flatFoot is to short
      if (thirdValue - secondValue < minSpace && thirdValue - secondValue > 25) {
        return secondValue - 5;
      }

      if (thirdValue - secondValue <= 25) {
        return secondValue - 15;
      }
      // If the space is good

      return secondValue;
    }

    // If the space between flatFoot and heelStrike is to short
    if (diff < minSpace) {
      return secondValue - minSpace;
    }

    // If the space between heelOff and flatFoot is to short
    if (thirdValue - firstValue < minSpace && thirdValue - firstValue > 25) {
      return firstValue + 5;
    }

    if (thirdValue - firstValue <= 25) {
      return firstValue + 15;
    }
    // If the space is good

    return firstValue;
  }

  getChartScale() {
    // Get extrem values
    const greaterValue = Math.round(
      Math.max(this.values.toesOff.left[this.mode].stdMax, this.values.toesOff.right[this.mode].stdMax)
    );
    const lowestValue = Math.round(
      Math.min(this.values.heelStrike.left[this.mode].stdMin, this.values.heelStrike.right[this.mode].stdMin)
    );
    const rangeGap = 10;

    let minScale = this.container.chart.scale.min;
    let maxScale = this.container.chart.scale.max;

    // Verif in 10 to 10 if scale is less than value
    for (const level of this.container.chart.scale.zones) {
      if (lowestValue <= this.container.chart.scale.min - level) {
        minScale = this.container.chart.scale.min - level - rangeGap;
        maxScale = this.container.chart.scale.max + level + rangeGap;
      }

      if (greaterValue >= this.container.chart.scale.max + level) {
        maxScale = this.container.chart.scale.max + level + rangeGap;
        minScale = this.container.chart.scale.min - level - rangeGap;
      }
    }

    this.container.chart.scale = {
      min: minScale,
      max: maxScale
    };
  }

  // Helped functions

  cutLargeText(text: string, xPos: number, yPos: number) {
    let index = 0;
    for (const caracter of text) {
      if (caracter === ' ' && index > 8) {
        this.ctx.fillText(text.slice(0, index), xPos, yPos - 6);
        this.ctx.fillText(text.slice(index + 1, text.length), xPos, yPos + 6);
        return;
      }

      index++;
    }
  }

  clean() {
    // Store the current transformation matrix
    this.ctx.save();

    // Use the identity matrix while clearing the canvas
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Restore the transform
    this.ctx.restore();
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }
}
