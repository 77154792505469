




















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabWidgetSymmetry',
  components: {
    ChartContainer
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const errorMargin = 2;

    const symmetryValue = properties.data.global.avg_symmetry;
    const symmetryPercent = Number(Math.abs(symmetryValue).toFixed());

    const symmetry = computed(() => {
      return { symmetryPercent };
    });

    const symmetryCssVariables = computed(() => {
      return {
        '--symmetry-gap': `${((100 - symmetryPercent) / 2).toFixed()}`,
        '--left-side': properties.data.global.side === 'left'
      };
    });

    const bottomErrorMargin = computed(() => Number((symmetryPercent - errorMargin).toFixed()));
    const topErrorMargin = computed(() => {
      const percent = symmetryPercent + errorMargin;
      return percent > 100 ? 100 : Number(percent.toFixed());
    });

    return {
      symmetry,
      symmetryCssVariables,
      bottomErrorMargin,
      topErrorMargin
    };
  }
});
