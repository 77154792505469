<template>
  <div class="chart">
    <div v-if="!hideTitle" class="chart__title">
      {{ $t('commons.sentences.angle-pro-sup') }}
    </div>
    <div v-if="analysisDate" class="chart__date">{{ $moment(analysisDate).format('LLL') }}</div>
    <div class="chart__content">
      <div class="chart">
        <ChartWalkingPronationSupinationAnglesAdvanced
          v-if="advancedMode"
          v-bind="{ aggregates, norms, height }"
          :relative="false"
          simple
        />
        <div v-else>
          <ChartWalkingGroupStepsAngles v-bind="{ aggregates }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartWalkingGroupStepsAngles from '@/components/charts/walking/groups/ChartWalkingGroupStepsAngles';
import ChartWalkingPronationSupinationAnglesAdvanced from '@/components/charts/walking/ChartWalkingPronationSupinationAnglesAdvanced.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingChartPronationAngles',
  components: {
    ChartWalkingGroupStepsAngles,
    ChartWalkingPronationSupinationAnglesAdvanced
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    advancedMode: {
      type: Boolean
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    }
  }
});
</script>

<style lang="scss" scoped>
.chart {
  &__title {
    font-size: 1rem;
  }

  &__content {
    margin-top: 20px;

    .chart {
      position: relative;
      .fill-width {
        width: 100%;
      }
    }
  }
}
</style>
