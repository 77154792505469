














// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionContainer from '@/components/charts/ChartEvolutionContainer.vue';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils ------------------------------------------------------------------------------------
import { useChart, EThemeColor } from '@/utils/charts.utils';
import { EUnitKey } from '@/utils/parameter-units.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionSymmetry',
  components: {
    ChartEvolutionContainer
  },
  props: {
    manifest: {
      type: Array,
      required: true
    },
    symmetry: {
      type: Object
    },
    norms: {
      type: Object
    }
  },
  setup(properties) {
    const {
      evolutionChartStructure,
      getGlobalData,
      getGlobalDataVariations,
      getSerieItemStructure,
      getNormsStructure
    } = useChart();

    const title = 'commons.standards.symmetry';

    const symmetry: any = computed(() => getGlobalData(properties.symmetry));

    const normsType = computed(() => {
      return {
        base: [properties.norms?.min[0], properties.norms?.max[0]]
      };
    });

    const dataVariations = computed(() => getGlobalDataVariations(symmetry.value?.global));

    const valid = computed(() => !!symmetry.value);

    const series = computed(() => {
      let data = [
        getSerieItemStructure(
          'symmetry',
          `${i18n.t('commons.standards.symmetry')}`,
          symmetry.value?.global,
          EThemeColor.Chart1
        ),
        getSerieItemStructure('symmetry', undefined, symmetry.value?.ranges, EThemeColor.Chart1, true)
      ];
      // Have norms
      if (properties.norms)
        data.push(getNormsStructure('symmetry_norms', properties.norms as { min: number[]; max: number[] }));

      return data;
    });

    const chartOptions = computed(() => {
      return {
        ...evolutionChartStructure(title, properties.manifest, EUnitKey.Percent, 0),
        series: series.value
      };
    });

    return {
      title,
      dataVariations,
      chartOptions,
      normsType,
      valid
    };
  }
});
