























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRehabGeneralParameters from '@/components/charts/rehab/ChartRehabGeneralParameters.vue';
// Import utils ------------------------------------------------------------------------------------
import { useRehab } from '@/utils/rehab.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameJumpingChartSingleHopGeneralParameters',
  components: {
    ChartRehabGeneralParameters
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    isAdvancedMode: {
      type: Boolean,
      required: true
    },
    activityDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    }
  },
  setup(properties) {
    const { getSingleHopGeneralParametersSimplifiedData, getSingleHopGeneralParametersAdvancedData } =
      useRehab();

    const simplifiedModeCharts = getSingleHopGeneralParametersSimplifiedData(properties.aggregates);
    const advancedModeCharts = getSingleHopGeneralParametersAdvancedData(properties.aggregates);

    return {
      simplifiedModeCharts,
      advancedModeCharts
    };
  }
});
