










































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, reactive, PropType } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ElementResultsTooltip from '@/components/elements/ElementResultsTooltip.vue';
import ElementChartUnvailable from '@/components/elements/ElementChartUnvailable.vue';
import GlobalIcon from '@/components/globals/GlobalIcon.vue';
// Import types ------------------------------------------------------------------------------------
import { EVariationType, EVariationStatus } from '@/utils/charts.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionContainer',
  components: {
    ElementResultsTooltip,
    ElementChartUnvailable,
    GlobalIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    global: {
      type: Boolean
    },
    multiple: {
      type: Boolean
    },
    tooltip: {
      type: Array,
      required: false
    },
    dataVariations: {
      type: Object as PropType<any>,
      required: false
    },
    valid: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const icons = reactive({
      [EVariationType.UP]: 'arrow-trend-up',
      [EVariationType.DOWN]: 'arrow-trend-down',
      [EVariationType.NEUTRAL]: 'check'
    });
    const signs = reactive({
      [EVariationType.UP]: '+',
      [EVariationType.DOWN]: '-',
      [EVariationType.NEUTRAL]: ''
    });
    const colors = reactive({
      [EVariationStatus.GOOD]: 'success',
      [EVariationStatus.BAD]: 'error',
      [EVariationStatus.NEUTRAL]: 'success'
    });

    return {
      icons,
      signs,
      colors
    };
  }
});
