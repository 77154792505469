












































































































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, reactive, watch, onMounted, Ref } from '@vue/composition-api';
import { useLocalStorage } from '@vueuse/core';
// Import components -------------------------------------------------------------------------------
import ElementEvolutionFilter from '@/components/elements/ElementEvolutionFilter.vue';
import ElementResultsSwitchRelative from '@/components/elements/ElementResultsSwitchRelative.vue';
// Import helpers ----------------------------------------------------------------------------------
import { staticValues } from '@/helpers/static-values.helper';
// Import types ------------------------------------------------------------------------------------
import { AnalysisType } from '@/plugins/podocore/repositories/analysis.repository';
// -------------------------------------------------------------------------------------------------

enum ScenarioKey {
  WalkingEmbedded = 'walking_embedded',
  RunningEmbedded = 'running_embedded',
  JumpingEmbedded = 'jumping_embedded'
}
export enum EAnalysisName {
  Walking = 'walk',
  Running = 'run',
  Jumping = 'jumping'
}
export enum EAnalysisType {
  Walking = 'walking',
  Running = 'running',
  Jumping = 'jumping'
}
export type TAnalysisNames = {
  [AnalysisType.walking]: EAnalysisName.Walking;
  [AnalysisType.running]: EAnalysisName.Running;
  [AnalysisType.jumping]: EAnalysisName.Jumping;
};
export type TAnalysisType = {
  [AnalysisType.walking]: EAnalysisType.Walking;
  [AnalysisType.running]: EAnalysisType.Running;
  [AnalysisType.jumping]: EAnalysisType.Jumping;
};

export default defineComponent({
  name: 'PatientEvolutionHeader',
  components: {
    ElementResultsSwitchRelative,
    ElementEvolutionFilter
  },
  props: {
    degradedAnalysesNumber: {
      type: Number,
      required: true
    },
    hideDegradedAnalyses: {
      type: Boolean,
      required: true
    },
    evolutionIsNotFound: {
      type: Boolean,
      required: true
    },
    mixEnvironnementAnalysisStatus: {
      type: Object,
      required: true
    },
    haveMixEnvironnementAnalysis: Boolean
  },
  setup(properties, { emit }) {
    const { scenarioKeyToAnalysisType } = staticValues();

    const scenarioKey = ref(ScenarioKey.WalkingEmbedded);
    const relative = ref(false);

    const nameByAnalysisType: TAnalysisNames = reactive({
      [AnalysisType.walking]: EAnalysisName.Walking,
      [AnalysisType.running]: EAnalysisName.Running,
      [AnalysisType.jumping]: EAnalysisName.Jumping
    });
    const refactoredAnalysisType: TAnalysisType = reactive({
      [AnalysisType.walking]: EAnalysisType.Walking,
      [AnalysisType.running]: EAnalysisType.Running,
      [AnalysisType.jumping]: EAnalysisType.Jumping
    });

    type TJumpingModeType = 'CMJ' | 'triple-hop' | 'single-hop' | 'side-hop';

    type TJumpingModeKey =
      | 'jumping_CMJ_raw'
      | 'jumping_triple_hop_raw'
      | 'jumping_single_hop_raw'
      | 'jumping_side_hop_raw';

    type TJumpingMode = {
      type: TJumpingModeType;
      key: TJumpingModeKey;
    };

    type TAnalysisEnvironementType = 'dspro' | 'podosmart';

    type TWalkingMode = {
      type: TAnalysisEnvironementType;
      key: string;
    };
    type TRunningMode = {
      type: TAnalysisEnvironementType;
      key: string;
    };

    const walkingModes: TWalkingMode[] = [
      {
        type: 'dspro',
        key: 'DigitsolePro'
      },
      {
        type: 'podosmart',
        key: 'PodoSmart'
      }
    ];

    const runningModes: TRunningMode[] = [
      {
        type: 'dspro',
        key: 'DigitsolePro'
      },
      {
        type: 'podosmart',
        key: 'PodoSmart'
      }
    ];

    const jumpingModes: TJumpingMode[] = [
      {
        type: 'CMJ',
        key: 'jumping_CMJ_raw'
      },
      {
        type: 'triple-hop',
        key: 'jumping_triple_hop_raw'
      },
      {
        type: 'single-hop',
        key: 'jumping_single_hop_raw'
      },
      {
        type: 'side-hop',
        key: 'jumping_side_hop_raw'
      }
    ];

    const getJumpingModeType = (key: TJumpingModeKey) => {
      return jumpingModes.find((mode) => mode.key === key)?.type;
    };

    const jumpingModeKey = ref<TJumpingModeKey>();
    const walkingModeType = ref<TAnalysisEnvironementType>();
    const runningModeType = ref<TAnalysisEnvironementType>();

    // Type of view
    const isDefaultView: Ref<boolean> = useLocalStorage('isDefaultView', true);
    function setSelectedFilter(filter: string) {
      emit('setSelectedFilter', filter);
    }
    function setDefaultView(defaultView: boolean) {
      isDefaultView.value = defaultView;
    }

    // PDF export
    function openExport() {
      emit('openExport');
    }

    watch(scenarioKey, (_scenarioKey) => {
      if (!_scenarioKey.startsWith('jumping')) emit('setScenarioKey', _scenarioKey);
    });

    watch(
      jumpingModeKey,
      (_jumpingKey) => {
        if (_jumpingKey) {
          scenarioKey.value = ScenarioKey.JumpingEmbedded;
          if (scenarioKey.value === ScenarioKey.JumpingEmbedded) emit('setScenarioKey', _jumpingKey);
        }
      },
      { deep: true }
    );

    watch(
      walkingModeType,
      (_walkingType) => {
        if (_walkingType) {
          emit('setScenarioKey', ScenarioKey.WalkingEmbedded);
          emit('setWalkingType', _walkingType);
        }
      },
      { deep: true }
    );
    watch(
      runningModeType,
      (_runningType) => {
        if (_runningType) {
          emit('setScenarioKey', ScenarioKey.RunningEmbedded);
          emit('setRunningType', _runningType);
        }
      },
      { deep: true }
    );

    watch(relative, (_relative) => {
      emit('setRelativeMode', _relative);
    });

    function toggleHideDegradedAnalyses() {
      emit('toggleHideDegradedAnalyses', properties.hideDegradedAnalyses);
    }

    const onWalkRunButtonClick = (scenario: ScenarioKey) => {
      scenarioKey.value = scenario;
      jumpingModeKey.value = undefined;
    };

    onMounted(() => {
      if (properties.mixEnvironnementAnalysisStatus.walking) walkingModeType.value = 'dspro';
      if (properties.mixEnvironnementAnalysisStatus.running) runningModeType.value = 'dspro';
    });

    return {
      // Values
      scenarioKey,
      scenarioKeyToAnalysisType,
      relative,
      walkingModes,
      walkingModeType,
      runningModes,
      runningModeType,
      jumpingModes,
      jumpingModeKey,
      isDefaultView,
      // Helpers
      nameByAnalysisType,
      refactoredAnalysisType,
      // Enums
      ScenarioKey,
      // Emit
      setSelectedFilter,
      setDefaultView,
      // Methods
      openExport,
      getJumpingModeType,
      onWalkRunButtonClick,
      toggleHideDegradedAnalyses
    };
  }
});
