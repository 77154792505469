<template>
  <div class="document">
    <ExportationViewerPage :total="pageTotal" page="1">
      <ExportationFrameHeader v-bind="{ headerInformations }" />

      <ExportationFrameComparison
        v-bind="{ analysis }"
        class="top-margin-lg"
        :chart="ExportationFrameRunningChartBanner"
        rehab
      />
    </ExportationViewerPage>
    <ExportationViewerPage :total="pageTotal" page="2">
      <span class="export-comparison-chart-title">{{
        `${$t('commons.standards.cadence')} / ${$t('commons.standards.speed')}`
      }}</span>
      <ExportationFrameComparison v-bind="{ analysis }" :chart="ExportationFrameRunningChartSpeedCadence" />
    </ExportationViewerPage>

    <template v-if="advancedMode">
      <ExportationViewerPage :total="pageTotal" page="3">
        <span class="export-comparison-chart-title">{{ $t('commons.standards.running-cycle') }}</span>
        <ExportationFrameComparison v-bind="{ analysis }" :chart="ExportationFrameRunningChartRunProfile" />
      </ExportationViewerPage>

      <ExportationViewerPage :total="pageTotal" page="4">
        <span class="export-comparison-chart-title">{{ $t('commons.sentences.angle-pro-sup') }}</span>
        <ExportationFrameComparison
          v-bind="{ analysis }"
          :chart="ExportationFrameRunningChartPronationAngles"
          :options="{ height: 350, advancedMode: true }"
          rehab
        />
      </ExportationViewerPage>

      <ExportationViewerPage :total="pageTotal" page="5">
        <span class="export-comparison-chart-title">{{ $t('commons.standards.strike-pattern') }}</span>
        <ExportationFrameComparison
          v-bind="{ analysis }"
          :chart="ExportationFrameRunningChartStrikePattern"
        />

        <ExportationFrameComments
          @setComments="setComments($event)"
          @setActions="setActions($event)"
          class="top-margin-lg"
          :height="270"
        />
      </ExportationViewerPage>
    </template>

    <ExportationViewerPage v-else :total="pageTotal" page="3">
      <span class="export-comparison-chart-title">{{ $t('commons.standards.running-cycle') }}</span>
      <ExportationFrameComparison v-bind="{ analysis }" :chart="ExportationFrameRunningChartRunProfile" />

      <ExportationFrameComments
        @setComments="setComments($event)"
        @setActions="setActions($event)"
        class="top-margin-lg"
        :height="270"
      />
    </ExportationViewerPage>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ExportationViewerPage from '@/components/exportation/ExportationViewerPage.vue';
import ExportationFrameHeader from '@/components/exportation/frames/ExportationFrameHeader.vue';
import ExportationFrameComments from '@/components/exportation/frames/ExportationFrameComments.vue';
import ExportationFrameComparison from '@/components/exportation/frames/ExportationFrameComparison.vue';
// Charts
import ExportationFrameRunningChartBanner from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartBanner.vue';
import ExportationFrameRunningChartSpeedCadence from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartSpeedCadence.vue';
import ExportationFrameRunningChartRunProfile from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartRunProfile.vue';
import ExportationFrameRunningChartPronationAngles from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartPronationAngles.vue';
import ExportationFrameRunningChartStrikePattern from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartStrikePattern.vue';

export default defineComponent({
  name: 'ExportationFrameRunningComparison',
  components: {
    ExportationViewerPage,
    ExportationFrameHeader,
    ExportationFrameComments,
    ExportationFrameComparison
  },
  props: {
    analysis: {
      type: Object,
      required: true
    },
    advancedMode: {
      type: Boolean,
      required: true
    },
    pageTotal: {
      type: Number,
      required: true,
      default: 0
    },
    headerInformations: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      // Components
      ExportationFrameRunningChartBanner,
      ExportationFrameRunningChartSpeedCadence,
      ExportationFrameRunningChartRunProfile,
      ExportationFrameRunningChartPronationAngles,
      ExportationFrameRunningChartStrikePattern
    };
  }
});
</script>

<style lang="scss" scoped>
.top-margin {
  margin-top: 30px;
  &-sm {
    margin-top: 20px;
  }
  &-lg {
    margin-top: 50px;
  }
}
.align-row {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 50px;
}
.export-comparison-chart-title {
  font-size: 15px;
  font-weight: 500;
}
</style>
