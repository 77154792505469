

























































































































































































































































































































































































































































































































































































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, computed, Ref, toRefs, ComputedRef } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ElementResultsTooltip from '@/components/elements/ElementResultsTooltip.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export enum TSide {
  Left = 'left_foot',
  Right = 'right_foot'
}

export enum TDataIndex {
  First = 'first',
  Second = 'second'
}

export type TValues = {
  running_speed: TIndexValue;
  running_step_cadence: TIndexValue;
  running_stance_asymmetry: TIndexValue;
  running_strike_pattern: TSideValue;
  running_plantar_flexion_angle_foot_in: TSideValue;
  running_impact_force: TSideValue;
  running_stride_length: TSideValue;
  running_proportion_stance_time: TSideValue;
  running_proportion_swing_time: TSideValue;
  running_proportion_flight_time: TSideValue;
  running_proportion_loading_time: TSideValue;
  running_proportion_propulsion_time: TSideValue;
  running_pronation_angle_foot_in: TSideValue;
  running_pronation_angle_mid: TSideValue;
  running_pronation_angle_foot_out: TSideValue;
};

export type TSideValue = {
  left_foot: TIndexValue;
  right_foot: TIndexValue;
};

export type TIndexValue = {
  first: TValue;
  second: TValue | null;
};

export type TValue = {
  display_metrics: boolean;
  value: any;
};

export default defineComponent({
  name: 'ChartRunningDetailledTable',
  components: {
    ElementResultsTooltip
  },
  props: {
    segments: {
      type: Object,
      required: true
    },
    firstValue: {
      type: Object,
      default: null
    },
    secondValue: {
      type: Object,
      default: null
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    imperial: {
      type: Boolean
    },
    exportation: {
      type: Boolean
    }
  },
  setup(properties) {
    const { segments, firstValue, secondValue } = toRefs(properties);

    const sides: Ref<TSide[]> = ref([TSide.Left, TSide.Right]);
    const dataIndex: Ref<TDataIndex[]> = ref([TDataIndex.First, TDataIndex.Second]);

    const speedUnit = computed(() => getUnit(EValueTypeUnit.Speed));
    const strideLengthUnit = computed(() => getUnit(EValueTypeUnit.StrideLength));
    const avgUnit = computed(() => (properties.imperial ? 'imp_avg' : 'avg'));

    const values: ComputedRef<TValues> = computed(() => {
      const _values: TValues | any = {
        segment_duration: {},
        running_speed: {},
        running_step_cadence: {},
        running_stance_asymmetry: {},
        running_strike_pattern: {},
        running_plantar_flexion_angle_foot_in: {},
        running_impact_force: {},
        running_stride_length: {},
        running_proportion_stance_time: {},
        running_proportion_swing_time: {},
        running_proportion_flight_time: {},
        running_proportion_loading_time: {},
        running_proportion_propulsion_time: {},
        running_pronation_angle_foot_in: {},
        running_pronation_angle_mid: {},
        running_pronation_angle_foot_out: {}
      };

      for (const index of dataIndex.value) {
        const indexValue: any = index === TDataIndex.First ? firstValue.value : secondValue.value;
        for (const parameter of [
          'running_speed',
          'running_step_cadence',
          'running_stance_asymmetry',
          'running_strike_pattern',
          'running_plantar_flexion_angle_foot_in',
          'running_impact_force',
          'running_stride_length',
          'running_proportion_stance_time',
          'running_proportion_swing_time',
          'running_proportion_flight_time',
          'running_proportion_loading_time',
          'running_proportion_propulsion_time',
          'running_pronation_angle_foot_in',
          'running_pronation_angle_mid',
          'running_pronation_angle_foot_out'
        ]) {
          if (indexValue && segments.value[parameter]) {
            _values[parameter][index] = segments.value[parameter].global
              ? {
                  value: getParametersByIndex(segments.value[parameter]!.global, indexValue.index),
                  display_metrics: !!segments.value[parameter]!.global.avg[indexValue.index]
                }
              : {
                  left_foot: {
                    value: getParametersByIndex(segments.value[parameter]!.left_foot, indexValue.index),
                    display_metrics: !!segments.value[parameter]!.left_foot.avg[indexValue.index]
                  },
                  right_foot: {
                    value: getParametersByIndex(segments.value[parameter]!.right_foot, indexValue.index),
                    display_metrics: !!segments.value[parameter]!.right_foot.avg[indexValue.index]
                  }
                };
          } else {
            _values[parameter][index] = null;
          }
        }
      }
      for (const index of dataIndex.value) {
        const parameter = 'segment_duration';
        const indexValue: any = index === TDataIndex.First ? firstValue.value : secondValue.value;
        if (indexValue && segments.value[parameter]) {
          _values[parameter][index] = {
            value: getParametersByIndex(segments.value[parameter]!.global, indexValue.index),
            display_metrics: !!segments.value[parameter]!.global.avg
          };
        }
      }
      // _values.segment_duration = segments.value.segment_duration;
      return _values;
    });

    function getParametersByIndex(data: any, index: number) {
      let _data: any = {};
      for (const [key, value] of Object.entries(data)) {
        _data[key] = value === null ? null : (value as any)[index];
      }
      return _data;
    }

    function getDifference(first: number | null, second: number | null) {
      if (first === null || second === null) return 'long-arrow-right';
      if (second > first) return 'long-arrow-up';
      if (second < first) return 'long-arrow-down';
      return 'long-arrow-right';
    }

    function getTimeClass(first: boolean) {
      if (!secondValue.value) {
        return 'first-time-value';
      } else if (firstValue.value.indexPosition < secondValue.value.indexPosition) {
        return first ? 'first-time-value' : 'second-time-value';
      } else {
        return first ? 'second-time-value' : 'first-time-value';
      }
    }

    return {
      // Values
      sides,
      dataIndex,
      values,
      // Units
      speedUnit,
      strideLengthUnit,
      avgUnit,
      // Methods
      getDifference,
      getTimeClass
    };
  }
});
