export function secToMs(value: number): number {
  return value * 1000;
}

export function mpsToKmh(value: number): number {
  return value * 3.6;
}

export function mToCm(value: number): number {
  return value * 100;
}
export function mToMm(value: number): number {
  return value * 1000;
}

export function mToKm(value: number): number {
  return value / 1000;
}

export function radToDeg(value: number): number {
  return (value * 180) / Math.PI;
}

export function stanceTimePercent(stanceTime: number, swingTime: number): number {
  if (stanceTime + swingTime === 0) return 0;
  return (stanceTime / (stanceTime + swingTime)) * 100;
}

export function swingTimePercent(stanceTime: number, swingTime: number): number {
  if (stanceTime + swingTime === 0) return 0;
  return (swingTime / (stanceTime + swingTime)) * 100;
}

export function getPercent(x1: number, x2: number, x3?: number): number {
  if (x3) {
    if (x2 + x3 === 0) return 0;
    return (x1 / (x2 + x3)) * 100;
  } else {
    if (x1 + x2 === 0) return 0;
    return (x1 / (x1 + x2)) * 100;
  }
}

export function getWalkingPercentPhase(
  type: string,
  taligrade: number,
  plantigrade: number,
  digitigrade: number
): number {
  const data: any = {
    taligrade,
    plantigrade,
    digitigrade
  };
  const total = taligrade + plantigrade + digitigrade;
  if (total === 0) return total;
  return (data[type] / total) * 100;
}

export function getRunningPhaseDuration(stanceTime: number, phase: number): number {
  return (stanceTime / 100) * phase * 1000;
}

export function getInchFromFeet(feetValue: number): number {
  return rounded((feetValue - Math.floor(feetValue)) * 12);
}

export function getFeetFromFeetAndInch(feet: number, inches: number): number {
  return rounded(feet + inches / 12, 1);
}

export function rounded(value: number, round?: number): number {
  return Number.parseFloat(value.toFixed(round ?? 0));
}
