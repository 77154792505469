var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exportation__viewer"},[_c('export-frame',_vm._b({ref:"rIframe"},'export-frame',{ patient: _vm.patient },false),[(_vm.walkingAnalysis)?[(_vm.comparisonMode)?_c('ExportationFrameWalkingComparison',_vm._b({},'ExportationFrameWalkingComparison',{ analysis: _vm.analysis, advancedMode: _vm.advancedMode, pageTotal: _vm.pageTotal, headerInformations: _vm.headerInformations },false)):_c('ExportationFrameWalking',_vm._b({},'ExportationFrameWalking',{ analysis: _vm.analysis, advancedMode: _vm.advancedMode, pageTotal: _vm.pageTotal, headerInformations: _vm.headerInformations, comments: _vm.analysisComments },false))]:_vm._e(),(_vm.runningAnalysis)?[(_vm.comparisonMode)?_c('ExportationFrameRunningComparison',_vm._b({},'ExportationFrameRunningComparison',{ analysis: _vm.analysis, advancedMode: _vm.advancedMode, pageTotal: _vm.pageTotal, headerInformations: _vm.headerInformations },false)):_c('ExportationFrameRunning',_vm._b({},'ExportationFrameRunning',{
          analysis: _vm.analysis,
          advancedMode: _vm.advancedMode,
          pageTotal: _vm.pageTotal,
          headerInformations: _vm.headerInformations,
          detailledRunningMode: _vm.detailledRunningMode,
          showDetails: _vm.showDetails,
          exportSelectedSegments: _vm.exportSelectedSegments,
          comments: _vm.analysisComments
        },false))]:_vm._e(),(_vm.jumpingAnalysis)?[(_vm.comparisonMode)?_c('ExportationFrameJumpingComparison',_vm._b({},'ExportationFrameJumpingComparison',{ analysis: _vm.analysis, advancedMode: _vm.advancedMode, pageTotal: _vm.pageTotal, headerInformations: _vm.headerInformations },false)):_c('ExportationFrameJumping',_vm._b({},'ExportationFrameJumping',{ analysis: _vm.analysis, advancedMode: _vm.advancedMode, pageTotal: _vm.pageTotal, headerInformations: _vm.headerInformations, comments: _vm.analysisComments },false))]:_vm._e()],2),(_vm.pending)?_c('VOverlay',{attrs:{"absolute":"","opacity":"0.8"}},[_c('VProgressCircular',{attrs:{"color":"white","indeterminate":"","size":"60"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }