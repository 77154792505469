





















































































































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { get } from 'lodash';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
import i18n from '@/plugins/i18n';
// Import utils ------------------------------------------------------------------------------------
import { useLocalStorage } from '@vueuse/core';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import ExportationViewer from '@/components/exportation/ExportationViewer.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export enum EResultsTab {
  General = 'general',
  Advanced = 'advanced'
}

export type TExportAnalysis = {
  informations: TExportAnalysisInformations | TExportAnalysisInformationsComparison;
  standards: any;
};

export type TExportAnalysisInformations = {
  analysisType: string;
  activityDate: Date;
  valid: number;
  activityDuration?: Date;
  exportSelectedSegments?: any;
};
export type TExportAnalysisInformationsComparison = {
  first: TExportAnalysisInformations;
  second: TExportAnalysisInformations;
};

export default defineComponent({
  name: 'DialogExportPDF',
  components: {
    CompositeDialog,
    ExportationViewer
  },
  setup() {
    // Dialog
    const dialogId: DialogId = 'analysis-export-pdf';

    // References
    const rDialog = ref<any>(null);
    const rFrame = ref<any>(null);

    // Services
    const workspacesService = usePodocoreModuleService('workspaces');

    const resultsTab = computed(() => get(rDialog, 'value.meta.resultsTab', EResultsTab.General));
    const scenario = computed(() => get(rDialog, 'value.meta.scenario', null));
    const analysis = computed(() => get(rDialog, 'value.meta.analysis', null));
    const patient = computed(() => get(rDialog, 'value.meta.patient', null));
    const mode = computed(() => get(rDialog, 'value.meta.mode', 'standard'));
    const exportSelectedSegments = computed(() => get(rDialog, 'value.meta.exportSelectedSegments', []));

    const comparisonMode = computed(() => mode.value === 'comparison');

    const detailledRunningMode = computed(
      () =>
        resultsTab.value === EResultsTab.Advanced &&
        exportSelectedSegments.value &&
        exportSelectedSegments.value.length > 1
    );

    const currentWorkspace = computed(() => {
      return (workspacesService.state.value as any).context.current;
    });

    const printDialogIsOpened = ref<boolean>(false);

    const pdfData = ref({
      doctor: {
        email: {
          label: 'commons.standards.email',
          value: true
        },
        phone: {
          label: 'commons.standards.phone',
          value: true
        },
        logo: {
          label: 'commons.standards.workspace-logo',
          value: true,
          hide: false
        }
      },
      patient: {
        name: {
          label: 'commons.standards.name',
          value: true
        },
        age: {
          label: 'commons.standards.age',
          value: true
        },
        gender: {
          label: 'commons.standards.gender',
          value: true
        },
        height: {
          label: 'commons.standards.height',
          value: true
        },
        weight: {
          label: 'commons.standards.weight',
          value: true
        }
      },
      analysis: {
        advanced: {
          label: i18n.t('commons.standards.advanced'),
          value: true
        },
        details: {
          label: i18n.tc('commons.standards.detail', 2),
          value: true
        }
      }
    });

    // PDF cache
    const pdfDataCache = useLocalStorage('exportPdfData', pdfData.value);

    const showDoctorData = computed(() => {
      return {
        email: pdfData.value.doctor.email.value,
        phone: pdfData.value.doctor.phone.value,
        workspaceLogo: pdfData.value.doctor.logo.value
      };
    });

    const showPatientData = computed(() => {
      return {
        name: pdfData.value.patient.name.value,
        age: pdfData.value.patient.age.value,
        gender: pdfData.value.patient.gender.value,
        height: pdfData.value.patient.height.value,
        weight: pdfData.value.patient.weight.value
      };
    });

    function closeDialog() {
      rDialog.value.close();
    }

    onMounted(() => {
      pdfDataCache.value.doctor.logo.hide = !currentWorkspace.value._logoS3signedUrl;
      if (pdfDataCache.value) pdfData.value = pdfDataCache.value;
    });

    watch(
      pdfData,
      (value) => {
        pdfDataCache.value = value;
      },
      { deep: true }
    );

    watch(
      exportSelectedSegments,
      () => {
        if (detailledRunningMode.value) pdfData.value.analysis.details.value = true;
      },
      { deep: true }
    );

    function print() {
      // ExportationViewer
      let dialog = rDialog.value.$children[0].$children[0].$children[0];
      dialog = dialog.$children[dialog.$children.length - 1];
      dialog.print();
    }

    return {
      // References
      rFrame,
      rDialog,
      // Dialog
      dialogId,
      // analysis
      pdfData,
      currentWorkspace,
      scenario,
      analysis,
      patient,
      comparisonMode,
      showDoctorData,
      showPatientData,
      // Methods
      print,
      closeDialog,
      // Flags
      printDialogIsOpened,
      detailledRunningMode,
      exportSelectedSegments,
      resultsTab
    };
  }
});
