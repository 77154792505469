

















































// Import vendors ----------------------------------------------------------------------------------
import {
  ref,
  computed,
  defineComponent,
  watch,
  onUpdated,
  nextTick,
  Ref,
  PropType,
  onMounted,
  onBeforeUnmount
} from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
// Walking
import ExportationFrameWalking from '@/components/exportation/frames/walking/ExportationFrameWalking.vue';
import ExportationFrameWalkingComparison from '@/components/exportation/frames/walking/ExportationFrameWalkingComparison.vue';
// Running
import ExportationFrameRunning from '@/components/exportation/frames/running/ExportationFrameRunning.vue';
import ExportationFrameRunningComparison from '@/components/exportation/frames/running/ExportationFrameRunningComparison.vue';
// Jumping
import ExportationFrameJumping from '@/components/exportation/frames/jumping/ExportationFrameJumping.vue';
import ExportationFrameJumpingComparison from '@/components/exportation/frames/jumping/ExportationFrameJumpingComparison.vue';
// Import types ------------------------------------------------------------------------------------
import { Entity } from '@/plugins/podocore/helpers/repositories.helper';
import { Patient } from '@/plugins/podocore/repositories/patients.repository';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useRehab } from '@/utils/rehab.utils';
// Import config ---------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// -------------------------------------------------------------------------------------------------

export type TShowDoctorData = {
  email: boolean;
  phone: boolean;
};

export type TShowPatientData = {
  name: boolean;
  age: boolean;
  gender: boolean;
  height: boolean;
  weight: boolean;
};

export default defineComponent({
  name: 'ExportationViewer',
  components: {
    // Walking
    ExportationFrameWalking,
    ExportationFrameWalkingComparison,
    // Running
    ExportationFrameRunning,
    ExportationFrameRunningComparison,
    // Jumping
    ExportationFrameJumping,
    ExportationFrameJumpingComparison
  },
  props: {
    scenario: {
      type: String,
      required: true
    },
    analysis: {
      type: Object,
      required: true
    },
    patient: {
      type: Object as PropType<Entity<Patient>>,
      required: true
    },
    pending: {
      type: Boolean,
      required: true
    },
    mode: {
      type: String as PropType<'normal' | 'advanced'>,
      required: true
    },
    showDoctorData: {
      type: Object as PropType<TShowDoctorData>,
      required: true
    },
    showPatientData: {
      type: Object as PropType<TShowPatientData>,
      required: true
    },
    workspace: {
      type: Object,
      required: true
    },
    comparisonMode: {
      type: Boolean
    },
    detailledRunningMode: {
      type: Boolean
    },
    showDetails: {
      type: Boolean
    },
    exportSelectedSegments: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup(properties) {
    const rIframe: Ref<any | null> = ref(null);

    const { getMode } = useRehab();

    const pageTotal = ref(0);
    const comments = ref('');
    const actions = ref('');

    const walkingAnalysis = computed(() => {
      return properties.scenario.startsWith('walking');
    });
    const runningAnalysis = computed(() => {
      return properties.scenario.startsWith('running');
    });
    const jumpingAnalysis = computed(() => {
      return properties.scenario.startsWith('jumping');
    });
    const advancedMode = computed(() => properties.mode === 'advanced');

    const requestModule = usePodocoreModule('request');
    const { data, request, cancel } = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/analysis/${properties.analysis.cuid}/comments`
    );

    const analysisComments = computed(() => data.value?.docs);

    function print() {
      if (rIframe.value?.exportApp) rIframe.value.exportApp.print();
    }

    function setComments(_comments: string) {
      comments.value = _comments;
    }
    function setActions(_actions: string) {
      actions.value = _actions;
    }

    const headerInformations = computed(() => {
      return {
        analysis: properties.analysis,
        scenario: properties.scenario,
        patient: properties.patient,
        showDoctorData: properties.showDoctorData,
        showPatientData: properties.showPatientData,
        workspace: properties.workspace,
        comparisonMode: properties.comparisonMode
      };
    });

    onMounted(() => {
      request(); // comments
    });

    onBeforeUnmount(() => {
      cancel();
    });

    nextTick(() => {
      setTimeout(() => {
        if (rIframe.value.exportApp) {
          pageTotal.value =
            rIframe.value.exportApp.$children[rIframe.value.exportApp.$children.length - 1].$children.length;
        }
      }, 500);
    });

    watch(
      properties,
      () => {
        nextTick(() => {
          nextTick(() => {
            if (rIframe.value.exportApp) {
              pageTotal.value =
                rIframe.value.exportApp.$children[
                  rIframe.value.exportApp.$children.length - 1
                ].$children.length;
            }
          });
        });
      },
      {
        deep: true,
        immediate: true
      }
    );

    onUpdated(() => {
      nextTick(() => {
        nextTick(() => {
          if (rIframe.value.exportApp) {
            pageTotal.value =
              rIframe.value.exportApp.$children[
                rIframe.value.exportApp.$children.length - 1
              ].$children.length;
          }
        });
      });
    });

    return {
      // References
      rIframe,
      // Values
      pageTotal,
      analysisComments,
      comments,
      actions,
      walkingAnalysis,
      runningAnalysis,
      jumpingAnalysis,
      advancedMode,
      headerInformations,
      // Methods
      print,
      setComments,
      setActions,
      getMode
    };
  }
});
