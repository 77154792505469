<template>
  <div class="document">
    <ExportationViewerPage :total="pageTotal" page="1">
      <ExportationFrameHeader v-bind="{ headerInformations }" />

      <ExportationFrameComparison
        v-bind="{ analysis }"
        class="top-margin-lg bottom-margin-lg"
        :chart="ExportationFrameWalkingChartBanner"
        :options="{ degraded: haveDegradedActivity }"
        rehab
      />

      <template v-if="haveDegradedActivity">
        <span class="export-comparison-chart-title top-margin">{{
          $t('commons.standards.walking-stage')
        }}</span>
        <ExportationFrameComparison
          v-bind="{ analysis }"
          :chart="ExportationFrameWalkingChartWalkProfileDegraded"
        />
      </template>
    </ExportationViewerPage>

    <!--Degraded-->
    <ExportationViewerPage v-if="haveDegradedActivity" :total="pageTotal" page="2">
      <span class="export-comparison-chart-title top-margin">{{
        $t('commons.standards.foot-progression-angle')
      }}</span>
      <ExportationFrameComparison
        v-bind="{ analysis }"
        class="top-margin"
        :chart="ExportationFrameWalkingChartFootProgressionAngle"
      />
      <ExportationFrameComments
        class="top-margin-lg"
        @setComments="setComments($event)"
        @setActions="setActions($event)"
        :height="300"
      />
    </ExportationViewerPage>

    <!--Standard-->
    <template v-else>
      <ExportationViewerPage :total="pageTotal" page="2">
        <span class="export-comparison-chart-title">{{ $t('commons.standards.walking-stage') }}</span>
        <ExportationFrameComparison
          v-bind="{ analysis }"
          :chart="ExportationFrameWalkingChartWalkProfile"
          :options="{ patient: headerInformations.patient }"
        />
        <span class="export-comparison-chart-title top-margin-lg">{{
          $t('commons.standards.propulsion-rate')
        }}</span>
        <ExportationFrameComparison
          v-bind="{ analysis }"
          :chart="ExportationFrameWalkingChartPropulsionRatio"
          :options="{ patient: headerInformations.patient }"
          align="row"
          center
        />
      </ExportationViewerPage>
      <ExportationViewerPage :total="pageTotal" page="3">
        <span class="export-comparison-chart-title">{{ $t('commons.standards.gait-line') }}</span>
        <ExportationFrameComparison
          v-bind="{ analysis }"
          :chart="ExportationFrameWalkingChartGaitLine"
          :options="{ patient: headerInformations.patient }"
          center
        />
      </ExportationViewerPage>
      <template v-if="advancedMode">
        <ExportationViewerPage :total="pageTotal" page="4">
          <span class="export-comparison-chart-title">{{ $t('commons.sentences.angle-pro-sup') }}</span>
          <ExportationFrameComparison
            v-bind="{ analysis }"
            :chart="ExportationFrameWalkingChartPronationAngles"
            :options="{ patient: headerInformations.patient, height: 450, advancedMode }"
          />
        </ExportationViewerPage>
        <ExportationViewerPage :total="pageTotal" page="5">
          <span class="export-comparison-chart-title">{{ $t('commons.sentences.angle-pro-sup') }}</span>
          <ExportationFrameComparison
            v-bind="{ analysis }"
            :chart="ExportationFrameWalkingChartPronationAngles"
            :options="{ patient: headerInformations.patient, height: 200 }"
          />
          <span class="export-comparison-chart-title top-margin">{{
            $t('commons.standards.foot-progression-angle')
          }}</span>
          <ExportationFrameComparison
            v-bind="{ analysis }"
            :chart="ExportationFrameWalkingChartFootProgressionAngle"
            align="row"
            center
          />
        </ExportationViewerPage>
        <ExportationViewerPage :total="pageTotal" page="6">
          <span class="export-comparison-chart-title">{{
            `${$t('commons.standards.clearance')} - ${$t('commons.standards.steppage')}`
          }}</span>
          <ExportationFrameComparison
            v-bind="{ analysis }"
            :chart="ExportationFrameWalkingChartClearanceSteppage"
            center
          />
        </ExportationViewerPage>
        <ExportationViewerPage :total="pageTotal" page="7">
          <ExportationFrameComments
            @setComments="setComments($event)"
            @setActions="setActions($event)"
            :height="270"
            vertical
          />
        </ExportationViewerPage>
      </template>
      <ExportationViewerPage v-else :total="pageTotal" page="4">
        <span class="export-comparison-chart-title">{{ $t('commons.sentences.angle-pro-sup') }}</span>
        <ExportationFrameComparison
          v-bind="{ analysis }"
          :chart="ExportationFrameWalkingChartPronationAngles"
          :options="{ patient: headerInformations.patient, height: 200 }"
        />
        <!--Comments-->
        <ExportationFrameComments
          v-if="!advancedMode"
          @setComments="setComments($event)"
          @setActions="setActions($event)"
          class="top-margin-lg"
          :height="300"
        />
      </ExportationViewerPage>
    </template>

    <!--endregion-->
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------
import ExportationViewerPage from '@/components/exportation/ExportationViewerPage.vue';
import ExportationFrameHeader from '@/components/exportation/frames/ExportationFrameHeader.vue';
import ExportationFrameComments from '@/components/exportation/frames/ExportationFrameComments.vue';
import ExportationFrameComparison from '@/components/exportation/frames/ExportationFrameComparison.vue';
// Charts
import ExportationFrameWalkingChartBanner from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartBanner.vue';
import ExportationFrameWalkingChartWalkProfile from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartWalkProfile.vue';
import ExportationFrameWalkingChartWalkProfileDegraded from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartWalkProfileDegraded.vue';
import ExportationFrameWalkingChartGaitLine from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartGaitLine.vue';
import ExportationFrameWalkingChartPropulsionRatio from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartPropulsionRatio.vue';
import ExportationFrameWalkingChartPronationAngles from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartPronationAngles.vue';
import ExportationFrameWalkingChartFootProgressionAngle from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartFootProgressionAngle.vue';
import ExportationFrameWalkingChartClearanceSteppage from '@/components/exportation/frames/walking/charts/ExportationFrameWalkingChartClearanceSteppage.vue';
// Import helper-----------------------------------------------------------------
import { isDegradedAnalysis } from '@/helpers/helped-functions.helper';
// -----------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingComparison',
  components: {
    ExportationViewerPage,
    ExportationFrameHeader,
    ExportationFrameComments,
    ExportationFrameComparison
  },
  props: {
    analysis: {
      type: Object,
      required: true
    },
    advancedMode: {
      type: Boolean,
      required: true
    },
    pageTotal: {
      type: Number,
      required: true,
      default: 0
    },
    headerInformations: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const haveDegradedActivity = computed(
      () => isDegradedAnalysis(properties.analysis.second) || isDegradedAnalysis(properties.analysis.second)
    );

    return {
      haveDegradedActivity,
      // Components
      ExportationFrameWalkingChartBanner,
      ExportationFrameWalkingChartWalkProfile,
      ExportationFrameWalkingChartWalkProfileDegraded,
      ExportationFrameWalkingChartGaitLine,
      ExportationFrameWalkingChartPropulsionRatio,
      ExportationFrameWalkingChartPronationAngles,
      ExportationFrameWalkingChartFootProgressionAngle,
      ExportationFrameWalkingChartClearanceSteppage
    };
  }
});
</script>

<style lang="scss" scoped>
.top-margin {
  margin-top: 30px;
  &-sm {
    margin-top: 20px;
  }
  &-lg {
    margin-top: 50px;
  }
}
.bottom-margin {
  margin-top: 30px;
  &-sm {
    margin-top: 20px;
  }
  &-lg {
    margin-top: 50px;
  }
}
.align-row {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 50px;
}
.export-comparison-chart-title {
  font-size: 15px;
  font-weight: 500;
}
</style>
